<template>
  <main
    :class="{ hidden: !active }"
    style="background: rgba(223, 243, 250, 1)"
    class="px-3 w-full sm:w-60 pt-4 mt-16 non-print-slip z-40"
  >
    <div class="bg-white rounded-md p-4">
      <div style="background: #aeb9de1c" class="flex items-stretch mb-8">
        <input
          placeholder="Search..."
          class="w-4/5 bg-transparent text-xs p-2"
          v-model="searchText"
          type="text"
        />
        <button
          class="w-1/5 bg-transparent"
          @click="searchText = ''"
          v-if="searchText"
          type="text"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-2 h-2 mx-auto"
            viewBox="0 0 386.67 386.67"
          >
            <path
              d="M386.67 45.56L341.1 0 193.33 147.77 45.56 0 0 45.56l147.77 147.77L0 341.1l45.56 45.57L193.33 238.9 341.1 386.67l45.57-45.57L238.9 193.33z"
            />
          </svg>
        </button>
      </div>
      <div v-if="!searchText">
        <ul class="my-8">
          <li
            data-test="go-home"
            class="text-link"
            @click="goToLink({ routeName: 'Dashboard' })"
          >
            <span
              ><img
                class="link-icon bigger"
                src="@/assets/navbar/home.svg"
                alt=""
            /></span>
            <span class="flex-1 section-title blue-color">Home</span>
          </li>
        </ul>
        <template v-for="(section, index) in sidebarLinks">
          <div class="mb-8" v-if="section.showSection" :key="index">
            <h5 class="text-sm mb-3 section-title">
              {{ section.sectionTitle }}
            </h5>
            <ul>
              <template v-for="(link, index) in section.subLinks">
                <li
                  @click="goToLink(link)"
                  :id="'data-link-' + link.name.toLowerCase()"
                  class="text-link"
                  :key="'A' + index"
                  :data-test="link.dataAttribute"
                  v-if="showAdminLink(link)"
                >
                  <span
                    ><img
                      class="link-icon"
                      :src="getImgUrl(section.folderRoute, link.icon)"
                      alt=""
                  /></span>
                  <span class="flex-1">{{ link.name }}</span>
                </li>
              </template>
            </ul>
          </div>
        </template>
        <ul class="my-8">
          <h5 class="text-sm mb-3 section-title flex gap-x-2 items-center">
            <img
              class="link-icon bigger"
              src="@/assets/navbar/chart-square.svg"
              alt=""
            />Growth Services
          </h5>
          <li class="text-link" @click="goToLink({ routeName: 'GWT' })">
            <span>
              <img class="w-4" src="@/assets/navbar/gwt-icon.svg" alt="" />
            </span>
            <span class="flex-1">Grow with Traction</span>
          </li>
          <!-- <li class="text-link" @click="goToLink({ routeName: 'TransactionMonitoring' })">
            <span>
              <img class="w-4" src="@/assets/navbar/gwt-icon.svg" alt="" />
            </span>
            <span class="flex-1">Transaction Monitoring</span>
          </li> -->
          <li
            class="text-link"
            @click="goToLink({ routeName: 'TabbedIntegrations' })"
          >
            <span>
              <img class="w-4" src="@/assets/navbar/gwt-icon.svg" alt="" />
            </span>
            <span class="flex-1">Integrations</span>
          </li>
        </ul>
        <ul class="my-8">
          <li
            data-test="settings-button"
            class="text-link"
            @click="goToLink({ routeName: 'AppSettings' })"
          >
            <span
              ><img
                class="link-icon bigger"
                src="@/assets/navbar/settings.svg"
                alt=""
            /></span>
            <span class="flex-1 section-title">Settings</span>
          </li>
        </ul>
        <ul v-if="isAdminAccount" class="my-8">
          <li
            data-test="settings-button"
            class="text-link"
            @click="goToLink({ routeName: 'Security' })"
          >
            <span
              ><img
                class="link-icon bigger"
                src="@/assets/navbar/security.svg"
                alt=""
            /></span>
            <span class="flex-1 section-title">Security</span>
          </li>
        </ul>
        <!-- <ul class="my-8">
          <li
            class="text-link"
            @click="goToLink({ routeName: 'Kyc' })"
          >
            <span
              ><img class="link-icon bigger" src="@/assets/navbar/personalcard.svg" alt=""
            /></span>
            <span class="flex-1 section-title">KYC</span>
          </li>
        </ul> -->
        <ul class="my-8">
          <li
            class="text-link"
            data-test="support-button"
            @click="goToLink({ routeName: 'Support' })"
          >
            <span
              ><img
                class="link-icon bigger"
                src="@/assets/navbar/support.svg"
                alt=""
            /></span>
            <span class="flex-1 section-title">Support</span>
          </li>
          <li data-test="logout-button" class="text-link" @click="logUserOut()">
            <span
              ><img class="link-icon bigger" src="@/assets/logout.svg" alt=""
            /></span>
            <span class="flex-1 section-title red">Logout</span>
          </li>
        </ul>
        <!-- <ul class="my-8">
          <li
            class="text-link"
            @click="goToLink({ routeName: 'TabOnlineCatalogue' })"
          >
            <span
              ><img class="link-icon bigger" src="@/assets/navbar/support.svg" alt=""
            /></span>
            <span class="flex-1 section-title">Online Catalogue</span>
          </li>
        </ul> -->
      </div>

      <div v-else>
        <div>
          <h4 class="text-xs font-bold mb-2">
            Search Results for "{{ searchText }}"
          </h4>
          <ul class="my-4" v-if="filteredResults.length > 0">
            <li
              @click="goToLink(foundLink)"
              class="text-link"
              v-for="(foundLink, index) in filteredResults"
              :key="'X' + index"
            >
              <span
                ><img
                  class="w-5"
                  :src="getImgUrl(foundLink.folderRoute, foundLink.icon)"
                  alt=""
              /></span>
              <span class="flex-1">{{ foundLink.name }}</span>
            </li>
          </ul>
          <span v-else class="text-xs">No Results for {{ searchText }}</span>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {
  CLEAR_ALL_DATA_IN_DB,
  CLEAR_KEY_IN_DB,
  CLEAR_LIST,
} from '../../browser-db-config/indexedDB';
import {
  DELETE_AUTH_USER,
  DELETE_LOCAL_DB_DATA,
  GET_USER_BUSINESS_DATA,
  GET_USER_BUSINESS_ID,
  GET_USER_DATA,
} from '../../browser-db-config/localStorage';
import { permissionsModules } from '../../views/settings/permission';

export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: null,
      user: GET_USER_DATA(),
      permissionsModules,
      authRole: GET_USER_DATA().role ? GET_USER_DATA().role.toLowerCase() : '',
      searchText: '',
      sidebarLinks: [
        {
          sectionTitle: 'Payment',
          folderRoute: 'navbar',
          showSection: true,
          subLinks: [
            {
              name: 'transactions',
              icon: 'transactions.svg',
              routeName: 'Payments',
              folderRoute: 'navbar',
              dataAttribute: 'transactions-button',
            },
            {
              name: 'Terminals',
              icon: 'terminals.svg',
              routeName: 'Hardware',
              folderRoute: 'navbar',
              dataAttribute: 'terminals-button',
              showLink: (GET_USER_DATA().role || '').toLowerCase() === 'admin',
            },
            {
              name: 'Traction Account',
              icon: 'tractionaccount.svg',
              routeName: 'FixedAccount',
              folderRoute: 'navbar',
              dataAttribute: 'transaction-account',
            },
            {
              name: 'Card Monitoring',
              icon: 'cardMonitoring.svg',
              routeName: 'CardMonitoring',
              folderRoute: 'navbar',
              dataAttribute: 'card-monitoring',
            },
            {
              name: 'Authorize Device',
              icon: 'tractionaccount.svg',
              routeName: 'AddDevice',
              folderRoute: 'navbar',
              dataAttribute: '',
              showLink:
                (GET_USER_BUSINESS_ID() === '61af512e412493000324299a' ||
                  GET_USER_BUSINESS_ID() === '61af43784124930003241a19' ||
                  GET_USER_BUSINESS_ID() === '621e17738af690000333c157') ===
                true,
            },
          ],
        },
        {
          sectionTitle: 'Financial Services',
          folderRoute: 'navbar',
          showSection: (GET_USER_DATA().role || '').toLowerCase() === 'admin',
          subLinks: [
            {
              name: 'Wallet',
              icon: 'card.svg',
              routeName: 'MainWallet',
              folderRoute: 'navbar',
              dataAttribute: 'wallet-menu',
            },
            {
              name: 'Loans',
              icon: 'transactions.svg',
              routeName: 'LoanMain',
              folderRoute: 'navbar',
              dataAttribute: 'loans-menu',
            },
            {
              name: 'Savings',
              icon: 'download.svg',
              routeName: 'SavingsWaitlist',
              folderRoute: 'navbar',
              dataAttribute: '',
            },
            {
              name: 'Pay Bills',
              icon: 'money.svg',
              routeName: 'PayBills',
              folderRoute: 'navbar',
              dataAttribute: '',
            },
          ],
        },
        // {
        //   sectionTitle: "Payment Channels",
        //   folderRoute: "navbar",
        //   showSection: true,
        //   subLinks: [
        //     {
        //       name: "Terminals",
        //       icon: "terminals.svg",
        //       routeName: "Hardware",
        //       folderRoute: "navbar",
        //       showLink: (GET_USER_DATA().role || '').toLowerCase() === 'admin',
        //     },
        //     {
        //       name: "Traction Account",
        //       icon: "tractionaccount.svg",
        //       routeName: "FixedAccount",
        //       folderRoute: "navbar",
        //     }
        //   ]
        // },
        {
          sectionTitle: 'Business Tools',
          folderRoute: 'navbar',
          showSection: true,
          subLinks: [
            {
              name: 'Point of Sale',
              icon: 'pos.svg',
              routeName: 'Pos',
              folderRoute: 'navbar',
              dataAttribute: '',
            },
            {
              name: 'Products',
              icon: 'products.svg',
              routeName: 'ProductsTab',
              folderRoute: 'navbar',
              dataAttribute: 'product-tab',
            },
            {
              name: 'Inventory Management',
              icon: 'products.svg',
              routeName: 'InventoryTab',
              folderRoute: 'navbar',
              dataAttribute: 'inventory-tab',
            },
            {
              name: 'Book Keeping',
              icon: 'bookkeeping.svg',
              routeName: 'SalesHistory',
              folderRoute: 'navbar',
              dataAttribute: 'book-keeping-button',
            },
            {
              name: 'Customers',
              icon: 'profile-2user.svg',
              routeName: 'Customers',
              folderRoute: 'navbar',
              dataAttribute: 'customer-button',
            },
            {
              name: 'Tickets',
              icon: 'tickets.svg',
              routeName: 'Tickets',
              folderRoute: 'navbar',
              dataAttribute: 'ticket-button',
            },
            {
              name: 'Invoices',
              icon: 'invoice.svg',
              routeName: 'Invoices',
              folderRoute: 'navbar',
              dataAttribute: 'invoices-button',
            },
            {
              name: 'Online Store',
              icon: 'transactions.svg',
              routeName: 'OnlineCatalogue',
              folderRoute: 'navbar',
              dataAttribute: 'side-menu-online-store',
            },
            {
              name: 'Insights',
              icon: 'insights.svg',
              routeName: 'Insights',
              folderRoute: 'navbar',
              dataAttribute: 'insight-button',
            },
          ],
        },
      ],
    };
  },
  watch: {
    $route(x) {
      if (x) {
        if (!this.active) {
        } else {
        }
      }
    },
  },
  created() {},
  mounted() {},
  computed: {
    filteredResults() {
      let outcome = this.sidebarLinks.filter;
      if (this.searchText) {
        const collatedArray = this.sidebarLinks
          .filter((section) => section.showSection === true)
          .map((section) => section.subLinks);
        console.log('collated array: ', collatedArray);
        const flattened = collatedArray.flat();
        outcome = flattened.filter(
          (sublink) =>
            (sublink.name || '')
              .toLowerCase()
              .includes(this.searchText.toLowerCase()) &&
            this.showAdminLink(sublink)
        );
        console.log('Result:', outcome);
      }
      return outcome;
    },
    isAdminAccount() {
      return this.authRole === 'admin';
    },
  },
  methods: {
    // toggle() {
    //   this.$emit("toggle");
    //   this.active = !this.active;
    // },
    confirmAsAPromise(msg) {
      return new Promise((resolve, reject) => {
        const confirmed = window.confirm(msg);
        return confirmed ? resolve(true) : reject(false);
      });
    },
    showAdminLink(link) {
      let outcome = true;
      if (link && typeof link.showLink === 'boolean') {
        outcome = link.showLink;
      }
      return outcome;
    },
    productionMode() {
      const isProd = process.env.NODE_ENV === 'production';
      console.log('isProd?', process.env.NODE_ENV, isProd);
      return isProd;
    },
    goToLink(link) {
      console.log('navigating to : ', link.routeName);
      this.$router.push({ name: link.routeName }).catch((err) => {
        // catch the error but show nothing
      });
    },
    // logoutUser() {
    //   // DELETE_LOCAL_DB_DATA('traction-app-user-data');
    //   // DELETE_LOCAL_DB_DATA('traction-app-current-user-business-data');
    //   localStorage.clear();
    // },
    getImgUrl(folder, iconName) {
      return require(`../../assets/navbar/${iconName}`);
    },
    async logUserOut() {
      CLEAR_LIST();
      this.confirmAsAPromise('Do you want to log out?').then(() => {
        this.$store
          .dispatch('LOGOUT', { user_id: this.user._id })
          .then((res) => {
            if (res.status) {
              console.log('Logged Out', this.user._id);
              CLEAR_LIST();
              // localStorage.clear();
              DELETE_AUTH_USER();
              sessionStorage.clear();
              this.$intercom.shutdown(); // Shutdown intercom
              this.$MixpanelReset(); // Reset Mixpanel
              this.clearAllPeriodicSyncInstructions();
              this.$router.replace('/login');
            } else {
              this.$store.commit('TOGGLE_LOADING_FOR_LOGOUT', false);
            }
          })
          .catch(() => {});
      });
      // if (confirm('Do you want to log out?')) {
      //   // Clear data in indexedDB
      //   CLEAR_LIST()
      //   // localStorage.clear();
      //   DELETE_AUTH_USER()
      //   sessionStorage.clear()
      //   this.clearAllPeriodicSyncInstructions()
      //   this.$router.replace('/login')
      // }
    },
  },
};
</script>

<style lang="postcss" scoped>
.increase-spacing-nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-link {
  color: rgba(37, 59, 149, 0.8);
  font-weight: 500;
  font-size: 14px;
  @apply text-xs
        capitalize
        flex
        gap-x-2
        items-center
        my-4
        cursor-pointer;
}

.section-title {
  font-weight: 500;
  color: rgba(124, 124, 122, 1);
  font-size: 16px;
}

.section-title.red {
  @apply text-red-600;
}

.section-title.blue-color {
  font-weight: 500;
  color: rgba(37, 59, 149, 1);
  font-size: 16px;
}

.home-link {
  color: rgba(124, 124, 122, 1);
  font-size: 16px;
  font-weight: 500;
  font-size: 16px;
  @apply capitalize
        flex
        gap-x-2
        items-center
        my-4
        cursor-pointer;
}

.link-icon {
  @apply w-4 h-4;
}

.link-icon.bigger {
  @apply w-5 h-5;
}

@media print {
  .non-print-slip {
    display: none;
  }
}
</style>
