<template>
  <main v-if="active" style="z-index: 99999">
    <div
      class="px-10 box-border fixed bottom-0 mb-10 flex justify-center w-full z-40"
    >
      <div
        :class="type ? 'bg-primaryBlue' : 'bg-red-600'"
        class="text-white px-6 py-3 text-xs items-center rounded shadow-md flex gap-1"
      >
        <slot></slot>
        <button @click="$emit('close')">
          <img src="@/assets/svg/close-icon.svg" />
        </button>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  emits: ["close"],
  props: {
    active: Boolean,
    timeout: {
      type: Number,
      default: 8000,
    },
    type: {
      type: Boolean,
    },
  },
  watch: {
    active(x) {
      if (x) {
        setTimeout(() => {
          this.$emit("close", false);
        }, this.timeout);
      }
    },
  },
};
</script>

<style></style>
