export const cleanupObject = (object) => {
  return Object.entries(object).reduce((acc, [key, value]) => {
    if (value || typeof value === "number") return { ...acc, [key]: value };
    return { ...acc };
  }, {});
};

export const urlToSearchParams = (url, object) => {
  if (Object.keys(object ?? {}).length) {
    return `${url}?${new URLSearchParams(cleanupObject(object)).toString()}`;
  }
  return url;
};

export const toSnakeCase = (str) => {
  return str.replace(/([A-Z])/g, "_$1").toLowerCase();
};

export const convertKeysToSnakeCase = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((item) => convertKeysToSnakeCase(item));
  } else if (obj !== null && typeof obj === "object") {
    return Object.keys(obj).reduce((acc, key) => {
      const camelCaseKey = toSnakeCase(key);
      acc[camelCaseKey] = convertKeysToSnakeCase(obj[key]);
      return acc;
    }, {});
  }
  return obj; // Return the value if it's not an object or array
};

export const paymentMethods = [
  {
    _id: "cash",
    name: "Cash",
  },
  {
    _id: "pos",
    name: "POS",
  },
  // {
  //   _id: "online",
  //   name: "Online",
  // },
  // {
  //   _id: "own_bank_account",
  //   name: "Bank Transfer",
  // },
  {
    _id: "fixed_account",
    name: "Bank transfer (Fixed)",
  },
  // {
  //   _id: "bank_transfer",
  //   name: "Bank transfer (Dynamic)",
  // },
];

export const getDiscountValue = (item, key = "salePrice") => {
  if (item.discount_type === "amount") {
    return item.discount;
  }

  if (item.discount_type === "percentage") {
    return (item.discount / 100) * (item[key] * item.quantity);
  }

  return 0;
};
