import { GET_USER_BUSINESS_ID } from "../../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../../http-config/trac-http-req";
import { urlToSearchParams } from "../../../utils";

const state = {
  allTransfers: null,
  transferRes: null,
  createTransfer: null,
};

const getters = {
  GET_TRANSFER_RES: (state) => state.transferRes,
  GET_ALL_TRANSFERS: (state) => state.allTransfers,
  GET_CREATE_TRANSFER_RES: (state) => state.createTransfer,
};

const actions = {
  FETCH_ALL_TRANSFERS_V2: async function (context, params) {
    const httpReq = {
      url: urlToSearchParams(
        `/v2/business/${GET_USER_BUSINESS_ID()}/transfers`,
        params
      ),
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    context.commit("SETUP_ALL_TRANSFERS", httpRes);

    return httpRes;
  },
  FETCH_ALL_TRANSFERS: async function (context, payload) {
    const httpReq = {
      url: `/v1/businesses/${GET_USER_BUSINESS_ID()}/transfers/stores/${
        payload._id
      }`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    context.commit("SETUP_ALL_TRANSFERS", httpRes);
  },
  CREATE_TRANSFER: async function (context, payload) {
    const httpReq = {
      url: `/v2/business/${GET_USER_BUSINESS_ID()}/transfers${
        payload.send_and_recieve ? "/send-and-recieve" : ""
      }`,
      method: "POST",
      authRequest: true,
      body: payload,
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    context.commit("SETUP_CREATE_TRANSFER", httpRes);
  },
  CANCEL_TRANSFER: async function (context, payload) {
    const httpReq = {
      url: `/v2/business/${GET_USER_BUSINESS_ID()}/transfers/cancel`,
      method: "PUT",
      authRequest: true,
      body: payload,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SEND_INVENTORY_TRANSFER_RES", httpRes);
  },
  HOLD_TRANSFER: async function (context, payload) {
    const httpReq = {
      url: `/v2/business/${GET_USER_BUSINESS_ID()}/transfers/${
        payload._id
      }/on_hold`,
      method: "PUT",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SEND_INVENTORY_TRANSFER_RES", httpRes);
  },
  RELEASE_TRANSFER: async function (context, payload) {
    const httpReq = {
      url: `/v2/business/${GET_USER_BUSINESS_ID()}/transfers/${
        payload.transfer_id
      }/release`,
      method: "PUT",
      authRequest: true,
      body: payload,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SEND_INVENTORY_TRANSFER_RES", httpRes);
  },
  ACCEPT_TRANSFER: async function (context, payload) {
    const httpReq = {
      url: `/v2/business/${GET_USER_BUSINESS_ID()}/transfers/recieve`,
      method: "PUT",
      authRequest: true,
      body: payload,
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    context.commit("SEND_INVENTORY_TRANSFER_RES", httpRes);
  },
};

const mutations = {
  SETUP_ALL_TRANSFERS: (state, data) => {
    state.allTransfers = data;
  },
  SETUP_CREATE_TRANSFER: (state, data) => {
    state.createTransfer = data;
  },
  SEND_INVENTORY_TRANSFER_RES: (state, data) => {
    state.transferRes = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
