import { GET_USER_TOKEN } from "../browser-db-config/localStorage";
import { HTTP_CLIENT } from "./trac-axios-config";
import WhiteListingMixin from "../security-module/WhiteListingMixin";

async function HTTP_REQUEST(httpRequestData, extraHeaders = {}) {
  let file = false;

  if (httpRequestData.fileUpload) {
    file = true;
  }

  if (httpRequestData.method === 'POST') return postRequest(httpRequestData, file, extraHeaders);
  else if (httpRequestData.method === 'GET') return getRequest(httpRequestData, extraHeaders);
  else if (httpRequestData.method === 'DELETE') return deleteRequest(httpRequestData, extraHeaders);
  else if (httpRequestData.method === 'PATCH') return patchRequest(httpRequestData, extraHeaders);
  else if (httpRequestData.method === 'PUT') return putRequest(httpRequestData, extraHeaders);
}

function setAuthHeader(file, extraHeaders) {
  let headers = { 
    'Authorization': `Bearer ${GET_USER_TOKEN()}`, 
    'fileUploadheader': file ? { 'Content-Type': 'multipart/form-data' } : {},
    'Platform': 'web',
    'PlatformVersion': process.env.VUE_APP_VERSION,
    'deviceID': WhiteListingMixin.methods.getDeviceId() ? WhiteListingMixin.methods.getDeviceId() : WhiteListingMixin.methods.setDeviceId()
  };
  headers = { ...headers, ...extraHeaders };
  return { headers };
}

async function postRequest(requestData, file, extraHeaders) {
  const requestConfig = requestData.authRequest ? setAuthHeader(file, extraHeaders) : {};
  try {
    const res = await HTTP_CLIENT.post(requestData.url, requestData.body, requestConfig);
    return res.data;
  } catch (error) {
    // console.log('Interceptor PostRequest Error:', error.response.data.message)
    return error.response.data;
  }
}

async function getRequest(requestData, extraHeaders) {
  const requestConfig = requestData.authRequest ? setAuthHeader(false, extraHeaders) : {};
  try {
    const res = await HTTP_CLIENT.get(requestData.url, requestConfig);
    return res.data;
  } catch (error) {
    return error;
  }
}

async function deleteRequest(requestData, extraHeaders) {
  const requestConfig = requestData.authRequest ? setAuthHeader(false, extraHeaders) : {};
  try {
    const res = await HTTP_CLIENT.delete(requestData.url, requestConfig);
    return res.data;
  } catch (error) {
    return error;
  }
}

async function patchRequest(requestData, extraHeaders) {
  const requestConfig = requestData.authRequest ? setAuthHeader(false, extraHeaders) : {};
  try {
    const res = await HTTP_CLIENT.patch(requestData.url, requestData.body, requestConfig);
    return res.data;
  } catch (error) {
    return error;
  }
}

async function putRequest(requestData, extraHeaders) {
  const requestConfig = requestData.authRequest ? setAuthHeader(false, extraHeaders) : {};
  try {
    const res = await HTTP_CLIENT.put(requestData.url, requestData.body, requestConfig);
    return res.data;
  } catch (error) {
    return error;
  }
}

export { HTTP_REQUEST };
