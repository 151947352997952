<template>
  <td class="text-xs whitespace-no-wrap p-4"><slot></slot></td>
</template>

<script>
export default {
  name: "TableRow",
};
</script>

<style lang="scss" scoped></style>
