<template>
  <div>
    <div :class="{ 'bg-white big-shadow py-4 px-6': !componentMode }">
      <h3 v-if="sectionTitle !== 'empty'" class="text-lg my-4 font-bold">
        {{ sectionTitle }}
      </h3>
      <div class="flex items-center gap-x-4 border-b-2 border-gray-300">
        <div
          class="text-sm cursor-pointer -mb py-2"
          style="margin-bottom: -2px"
          :class="{ selected: link.selected, unselected: !link.selected }"
          v-for="(link, index) in subLinks"
          :key="index"
          @click="selectTab(index)"
          :data-test="link.dataAttribute"
        >
          {{ link.name }}
        </div>
      </div>
    </div>

    <router-view
      v-if="!componentMode"
      name="tabbedView"
      :class="getRouterViewClassString"
    ></router-view>
    <component
      v-else
      :is="subLinks[getCurrentTabIndex].component"
      :props="subLinks[getCurrentTabIndex].componentProps"
      v-on="$listeners"
    ></component>
  </div>
</template>

<script>
export default {
  emits: ["selected", "set-selected-tab"],
  created() {
    this.setActiveTab();
  },
  props: {
    subLinks: {
      type: Array,
      default: () => {
        return [];
      },
    },
    sectionTitle: {
      type: String,
      default: "Page Title Here",
    },
    componentMode: {
      type: Boolean,
      default: false,
      required: false,
    },
    selectedTab: {
      type: String,
      default: "",
    },
  },
  computed: {
    getCurrentTabIndex() {
      const index = this.subLinks.findIndex((tab) => tab.selected === true);
      return index > -1 ? index : 0;
    },
    getRouterViewClassString() {
      return (
        this.subLinks[this.getCurrentTabIndex].routerWindowClassString || ""
      );
    },
  },
  methods: {
    selectTab(index = 0) {
      this.resetTabs();
      this.subLinks[index].selected = true;
      this.$emit("set-selected-tab", this.subLinks[index].name);
      this.openSubPage(this.subLinks[index]);
    },
    resetTabs() {
      this.subLinks.forEach((item) => (item.selected = false));
    },
    openSubPage(data) {
      !this.componentMode && this.$router.replace({ path: data.path });
    },
    setActiveTab() {
      const enteredPath = !this.componentMode
        ? this.$route.path
        : this.selectedTab;
      const subLinkIndex = this.subLinks.findIndex(
        (link) => link[!this.componentMode ? "path" : "name"] === enteredPath
      );
      if (subLinkIndex > -1) {
        this.selectTab(subLinkIndex);
      } else {
        this.subLinks[0].selected = true;
        this.selectTab();
      }
    },
  },
  watch: {
    selectedTab(val) {
      this.setActiveTab();
    },
  },
};
</script>

<style lang="postcss" scoped>
.unselected {
  @apply text-gray-600;
}
.selected {
  color: rgba(1, 53, 136, 1);
  @apply font-bold border-b-2 border-blue-700;
}
</style>
