<template>
  <div class="relative z-20">
    <div
      v-if="$attrs.inputProps"
      @click="showDropDown = !showDropDown"
      v-click-outside="toggleDropdown"
      class="flex relative"
    >
      <input
        class="border h-full w-full rounded cursor-pointer flex items-center p-3 text-gray-500 bg-white justify-between text-xs"
        v-bind="$attrs.inputProps"
        @input="$emit('change', $event.target.value)"
      />
      <div
        class="absolute"
        style="right: 10px; top: 5px; transform: translateY(50%)"
      >
        <svg
          class="w-4 h-4 opacity-50"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M4.516 7.548c.436-.446 1.043-.481 1.576 0L10 11.295l3.908-3.747c.533-.481 1.141-.446 1.574 0 .436.445.408 1.197 0 1.615-.406.418-4.695 4.502-4.695 4.502a1.095 1.095 0 0 1-1.576 0S4.924 9.581 4.516 9.163c-.409-.418-.436-1.17 0-1.615z"
          />
        </svg>
      </div>
    </div>
    <div
      v-else
      @click="showDropDown = !showDropDown"
      v-click-outside="toggleDropdown"
      class="border h-full rounded cursor-pointer flex items-center p-3 text-gray-500 bg-white justify-between"
    >
      <p class="text-xs text-black">
        {{ selectedIDs.length > 0 ? displaySelectedData : title }}
        <span
          v-if="selectedIDs.length > this.dropdownRestriction"
          class="rounded py-1 bg-backgroundArea shadow-xs inline-block mx-1 px-2"
        >
          <span class="text-xs text-black"> + {{ extrasSelected }} </span>
        </span>
      </p>
      <svg
        class="w-4 h-4 opacity-50"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path
          d="M4.516 7.548c.436-.446 1.043-.481 1.576 0L10 11.295l3.908-3.747c.533-.481 1.141-.446 1.574 0 .436.445.408 1.197 0 1.615-.406.418-4.695 4.502-4.695 4.502a1.095 1.095 0 0 1-1.576 0S4.924 9.581 4.516 9.163c-.409-.418-.436-1.17 0-1.615z"
        />
      </svg>
    </div>
    <div
      class="bg-white absolute border right-0 rounded-lg mt-2 max-w-2xl w-full"
      v-if="showDropDown"
    >
      <div @click.stop class="flex w-full p-4 items-center gap-3 bg-gray-100">
        <p class="text-xs whitespace-no-wrap font-semibold">
          {{ title }}
        </p>
        <input
          type="search"
          name=""
          v-model="searchedItem"
          id=""
          class="border w-full outline-none py-1 px-2 rounded-sm text-xs"
        />
      </div>
      <div
        @click.stop
        class="grid grid-cols-3 overflow-y-auto items-center mt-2 ring w-full gap-5 py-2 z-50 bg-white ring-1 ring-black ring-opacity-5"
      >
        <div
          class="flex items-center cursor-pointer capitalize text-xs"
          v-show="'all'.includes(searchedItem.toLowerCase())"
        >
          <input
            id="all-employees"
            type="checkbox"
            class="ml-4"
            value="all"
            :checked="selectedIDs.length === items.length"
            @input="selectItem"
          />
          <label class="ml-3 cursor-pointer" for="all-employees">All</label>
        </div>
        <div
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
          v-for="(item, index) in filteredItems"
          :key="index"
        >
          <div
            class="block px-4 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
          >
            <div class="flex items-center cursor-pointer capitalize text-xs">
              <input
                v-model="selectedIDs"
                :value="item._id || item.id"
                type="checkbox"
                :id="'option-' + index"
                class="mr-3"
                @input="selectItem"
              />
              <label class="cursor-pointer" :for="'option-' + index">{{
                item[displayProp]
              }}</label>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="filteredItems.length === 0 && searchedItem.length > 0"
        class="h-48 flex justify-center items-center"
      >
        <p class="text-xs text-gray-500">No match found</p>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  directives: {
    ClickOutside,
  },
  props: {
    items: {
      type: Array,
    },
    title: {
      type: String,
    },
    displayProp: {
      type: String,
    },
    allowInitialLoad: {
      type: Boolean,
      default: true,
    },
    reset: {
      type: Boolean,
      default: true,
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showDropDown: false,
      selectedIDs: [],
      searchedItem: "",
      dropdownRestriction: 2,
    };
  },
  computed: {
    extrasSelected() {
      return this.selectedIDs.slice(this.dropdownRestriction).length;
    },
    displaySelectedData() {
      return this.selectedIDs
        .map(
          (id) =>
            this[this.selectedItems.length ? "selectedItems" : "items"].find(
              (item) => (item._id || item.id) === id
            ) || {}
        )
        .map((item) => item[this.displayProp])
        .slice(0, this.dropdownRestriction)
        .join(", ");
    },
    filteredItems() {
      return this.items.filter((item) =>
        (item[this.displayProp] || "")
          .toLowerCase()
          .includes(this.searchedItem.toLowerCase())
      );
    },
  },
  mounted() {
    if (this.allowInitialLoad) {
      setTimeout(() => {
        this.selectItem({
          target: {
            value: "all",
            checked: true,
          },
        });
      }, 100);
    }
  },
  methods: {
    selectItem(e) {
      if (e.target.value === "all") {
        if (e.target.checked) {
          this.selectedIDs = (this.items || []).map(
            (item) => item._id || item.id
          );
        } else {
          this.selectedIDs = [];
        }
      }
      setTimeout(() => {
        this.$emit("input", this.selectedIDs);
      }, 10);
    },
    toggleDropdown() {
      this.showDropDown = false;
    },
  },
  watch: {
    reset() {
      this.selectedIDs = [];
    },
    selectedItems: {
      handler(values) {
        this.selectedIDs = (this.items || [])
          .map((item) => {
            const value = values.find((val) =>
              (val?.id || val?._id).includes(item._id || item.id)
            );

            if (value) return value?.id || value?._id;
            return null;
          })
          .filter(Boolean);
      },
      deep: true,
    },
  },
};
</script>
