<template>
  <div>
    <h1 class="text-base font-semibold text-blue-800 pb-4">Modifiers</h1>
    <div v-if="!product.modifiers.length">
      <!-- <h1 class="text-2xl font-bold text-gray-800">No tags available</h1> -->
      <!-- Create a header with small text uppercase "Product Tags" -->
      <p class="text-xs pb-4">
        Product modifiers is allowing users to customize and modify products
        based on their preferences. Product modifiers will include options such
        as size, color, and additional features that users can choose when
        purchasing a product
      </p>
      <button
        class="text-xs font-semibold text-blue-800 flex items-center gap-x-1"
        type="button"
        @click="toggleAddModal"
      >
        <img src="@/assets/tiny-plus.png" class="w-5 h-5" alt="" />
        Add Modifiers (Optional)
      </button>
    </div>

    <trac-add-modifier
      v-if="showAddModifierModal"
      @close="toggleAddModal"
      @update-modifiers="updateModifier"
    />

    <trac-dashboard-table
      v-if="product.modifiers.length"
      :tableHeaders="tableHeaders"
      :data="product.modifiers"
    >
      <template #default="{ row }">
        <trac-dashboard-table-data>{{ row.title }}</trac-dashboard-table-data>
        <trac-dashboard-table-data>
          {{ row.description }}
        </trac-dashboard-table-data>
        <trac-dashboard-table-data>
          {{ Number(row.stockQuantity).toLocaleString() }}
        </trac-dashboard-table-data>
        <trac-dashboard-table-data>
          <button @click="removeModifier(row.id)">
            <div class="w-3 h-3">
              <img src="@/assets/svg/close-icon.svg" class="w-full h-full" />
            </div>
          </button>
        </trac-dashboard-table-data>
      </template>
    </trac-dashboard-table>

    <div v-if="product.modifiers.length" class="flex justify-end">
      <button
        class="text-xs font-semibold text-blue-800 flex items-center gap-x-1 py-6"
        type="button"
        @click="toggleAddModal"
      >
        <img src="@/assets/tiny-plus.png" class="w-5 h-5" alt="" />
        Add Modifiers
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductModifiers",
  emits: ["update-modifiers"],
  props: {
    product: {
      type: Object,
      default: () => ({
        modifiers: [],
      }),
    },
  },
  data() {
    return {
      showAddModifierModal: false,
      loading: false,
      modifiers: [],
      tableHeaders: [
        {
          name: "NAME",
        },
        {
          name: "DETAILS",
        },
        {
          name: "QUANTITY",
        },
        {
          name: "",
          classValue: "w-1/6",
        },
      ],
    };
  },
  methods: {
    toggleAddModal() {
      this.showAddModifierModal = !this.showAddModifierModal;
    },
    updateModifier(data) {
      const allModifiers = [...this.product.modifiers, ...data];
      const uniqueModifiers = Array.from(
        new Set(allModifiers.map((mod) => mod.id))
      ).map((id) => allModifiers.find((mod) => mod.id === id));

      this.$emit("update-modifiers", uniqueModifiers);
      this.toggleAddModal();
    },
    removeModifier(id) {
      this.$emit(
        "update-modifiers",
        this.product.modifiers.filter((mod) => mod.id !== id)
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
