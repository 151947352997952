<template>
  <table class="w-full table-auto">
    <thead class="bg-blue-100">
      <tr>
        <th
          v-for="{ name, classValue } in tableHeaders"
          :key="name"
          class="text-left whitespace-no-wrap p-4 text-xs font-semibold"
          :class="classValue"
        >
          {{ name }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-if="!data.length">
        <slot name="empty">
          <td :colspan="tableHeaders.length">
            <div
              class="flex justify-center items-center w-full h-full bg-transparent"
            >
              <div class="h-40 flex items-center text-lg text-gray-600">
                No data to show.
              </div>
            </div>
          </td>
        </slot>
      </tr>
      <template v-else>
        <tr
          class="cursor-pointer hover:bg-gray-200"
          v-for="(row, index) in data"
          :key="index"
          :class="rowClass"
          @click="$emit('row-click', { row })"
        >
          <slot :row="row" :index="index"></slot>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "Table",
  emits: ["row-click"],
  props: {
    tableHeaders: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    rowClass: {
      type: [Array, String, Object],
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped></style>
