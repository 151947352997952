<template>
  <div class="h-48 md:w-48 relative" v-if="image || imageToUpload.src">
    <img
      class="h-40 w-full block"
      :src="imageToUpload.src || image"
      alt="image"
    />
    <input
      class="absolute bottom-0"
      accept="image/png, image/jpeg, image/jpg"
      @change="selectImage"
      type="file"
      name=""
      :id="id"
      v-show="false"
    />
    <button
      @click="removeImage"
      class="w-full p-2 text-sm bg-primaryGray text-white"
    >
      Remove Image
    </button>
  </div>
  <div v-else class="flex-wrap flex-column md:w-48">
    <div class="flex w-full h-40 no-img-bg relative">
      <input
        class="absolute bottom-0"
        accept="image/png, image/jpeg, image/jpg"
        @change="selectImage"
        type="file"
        name=""
        :id="id"
        v-show="false"
      />
      <div
        @click="triggerFileOpen"
        class="flex w-full md:w-48 cursor-pointer h-10 bg-primaryGray mt-auto text-white justify-center items-center font-medium text-sm"
      >
        Upload Image
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageUpload",
  emits: ["set-file-image"],
  props: {
    image: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "image-id",
    },
  },
  data() {
    return {
      imageToUpload: {},
    };
  },
  methods: {
    selectImage(e) {
      this.imageToUpload = {
        file: e.target.files[0],
      };

      this.imageToUpload.src = window.URL.createObjectURL(
        this.imageToUpload.file
      );
      console.log(this.imageToUpload);
      this.$emit("set-file-image", this.imageToUpload);
    },
    removeImage() {
      this.imageToUpload = {
        src: null,
        file: null,
      };
      this.$emit("set-file-image", "");
    },
    triggerFileOpen() {
      const fileInput = document.querySelector(`#${this.id}`);
      fileInput?.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.no-img-bg {
  background-color: #e2e2e2;
}
</style>
