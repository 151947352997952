<template>
  <button
    type="button"
    class="bg-veryLight text-primaryGray text-xs p-2 rounded-md flex items-center gap-3 focus:outline-none"
  >
    <span>{{ name }}</span>
    <button
      v-if="!hideCancelIcon"
      data-testid="cancel-btn"
      class="focus:outline-none"
      type="button"
      @click.stop="$emit('close')"
    >
      <img src="@/assets/svg/close-icon.svg" />
    </button>
  </button>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
    },
    hideCancelIcon: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close"],
};
</script>

<style lang="scss" scoped></style>
