import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";
import { urlToSearchParams } from "../../utils";

const state = {};

const getters = {};

const actions = {
  FETCH_ALL_PAYMENTS: async function (context, payload) {
    const httpReq = {
      url: `/v1/payments/single/businesses/${GET_USER_BUSINESS_ID()}?startdate=${
        payload.startDate
      }&enddate=${payload.endDate}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  FETCH_ALL_PAYMENTS_V2: async function (context, payload) {
    const httpReq = {
      url:
        `/v2/dashboard/gettransactionanalytics/${GET_USER_BUSINESS_ID()}?startdate=${
          payload.dateRange.startDate
        }&enddate=${payload.dateRange.endDate}&storeIds=${payload.storeIds}` +
        (payload.agentsId ? `&sales_agent_code_list=${payload.agentsId}` : ""),
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  FETCH_PAYMENTS_V2: async function (context, payload) {
    const httpReq = {
      url: urlToSearchParams(
        `/v1/payments/accounts/businesses/${GET_USER_BUSINESS_ID()}/${
          payload.paymentID
        }`,
        {
          storeID: payload.storeID,
        }
      ),
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  GET_BATCH_PAYMENT: async function (context) {
    const httpReq = {
      url: `/v1/payments/batch/businesses/${GET_USER_BUSINESS_ID()}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  GET_PAYMENT_LOG_DETAILS: async function (context, payload) {
    const httpReq = {
      url: `/v1/payments/paymentlogdetails/businesses/${GET_USER_BUSINESS_ID()}/paymentlogid/${payload}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },

  GET_PAYOUT_DETAILS: async function (context, payload) {
    const httpReq = {
      url: `/v1/payments/payoutdetails/businesses/${GET_USER_BUSINESS_ID()}/payoutid/${payload}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },

  GET_PAYMENT_CSV: async function (context, payload) {
    const httpReq = {
      // url: `/v1/payments/single/businesses/${GET_USER_BUSINESS_ID()}/report?startdate=${payload.startDate
      url: `/v1/payments/single/businesses/${GET_USER_BUSINESS_ID()}/report?startdate=${
        payload.startDate
      }&enddate=${payload.endDate}`,
      method: "GET",
      authRequest: true,
      // body: payload.storeIds,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
    // return {
    // 	"data": "https://s3-us-west-1.amazonaws.com/tractionapp-products/pkg/app/uploads/7665926482683838784_sales.csv",
    // 	"message": "data successfully fetched",
    // 	"status": true
    // };
  },
  GET_JSON_PAYMENT: async function (context, payload) {
    const httpReq = {
      url: `/v1/payments/reports/paymentlog/businesses/${GET_USER_BUSINESS_ID()}?startdate=${
        payload.startDate
      }&enddate=${payload.endDate}`,
      method: "POST",
      authRequest: true,
      body: payload.storeIds,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
};
const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
