<template>
  <trac-modal @close="$emit('close')">
    <div class="flex flex-col items-center gap-8 my-10">
      <div>
        <img src="@/assets/svg/check.svg" alt="check-icon" />
      </div>
      <slot />

      <div class="flex justify-center w-full close-btn" @click="$emit('close')">
        <trac-button class="uppercase text-xs w-1/2"> CLOSE </trac-button>
      </div>
    </div>
  </trac-modal>
</template>

<script>
export default {
  name: "SuccessModal",
  emits: ["close"],
};
</script>

<style lang="scss" scoped></style>
