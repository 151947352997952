<template>
  <div>
    <h1 class="text-base font-semibold text-blue-800 pb-4">Tags</h1>
    <div v-if="isEmpty">
      <!-- <h1 class="text-2xl font-bold text-gray-800">No tags available</h1> -->
      <!-- Create a header with small text uppercase "Product Tags" -->
      <p class="text-xs pb-4">
        This is used to organize your product by category, size, color,
        material, brand, model, storage capacity and much more...
      </p>
      <button
        class="text-xs font-semibold text-blue-800 flex items-center gap-x-1"
        type="button"
        @click="toggleAddModal"
      >
        <img src="@/assets/tiny-plus.png" class="w-5 h-5" alt="" />
        Add Tag (Optional)
      </button>
    </div>
    <div v-else>
      <table class="w-full table-auto">
        <thead class="bg-blue-100">
          <tr>
            <th
              class="text-left w-4/6 whitespace-no-wrap p-4 text-xs font-semibold"
            >
              TAGS
            </th>
            <th
              class="text-left w-1/6 whitespace-no-wrap p-4 text-xs font-semibold"
            >
              ACTIONS
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody v-if="tags.length <= 0">
          <tr>
            <td colspan="7">
              <div
                class="flex justify-center items-center w-full h-full bg-transparent"
              >
                <div class="h-40 flex items-center text-lg text-gray-600">
                  No product added yet.
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-if="selectedTags.length">
          <tr
            class="cursor-pointer hover:bg-gray-200"
            v-for="tag in selectedTags"
            :key="tag._id"
          >
            <td class="text-xs whitespace-no-wrap p-4 font-bold capitalize">
              {{ tag.tagName }}
            </td>
            <td class="text-xs whitespace-no-wrap p-4 font-bold capitalize">
              <!-- Create a dropdown with buttons for 'edit' and 'delete' using tailwind. Do NOT use x-show or x-data -->
              <div class="relative" v-click-outside="reset">
                <CreateTag
                  :loading="loading"
                  :show="showModal"
                  :tag-text="tagTextHolder"
                  :tag-id="tagIdHolder"
                  :tag-success-text="tagSuccessText"
                  :show-success="addTagSuccessModal"
                  @toggle-show="showModal = !showModal"
                  @toggle-success="toggleSuccessEdit"
                  @update-tag="updateTag"
                  @add-tag="addTag"
                >
                  <trac-button
                    @button-clicked="editTag(tag.tagName, tag._id)"
                    variant="outline"
                  >
                    Edit
                  </trac-button>
                </CreateTag>
              </div>
            </td>
            <td>
              <div class="flex justify-end items-center h-full">
                <button
                  @click="removeSelectedTag(tag._id)"
                  class="w-3 h-3 mr-8 mb-1"
                >
                  <img
                    class="w-full h-full"
                    src="@/assets/svg/close-icon.svg"
                  />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="selectedTags.length" class="flex justify-end">
        <button
          class="text-xs font-semibold text-blue-800 flex items-center gap-x-1 py-6"
          type="button"
          @click="toggleAddModal"
        >
          <img src="@/assets/tiny-plus.png" class="w-5 h-5" alt="" />
          Add Tag
        </button>
      </div>
    </div>

    <trac-modal v-if="showAddModal" @close="toggleAddModal">
      <p class="font-semibold">Add Tag</p>

      <div class="py-4">
        <trac-multi-select-dropdown
          @input="updateTags"
          title="Select tag"
          :allow-initial-load="false"
          :items="tags"
          :selectedItems="selectedTags"
          displayProp="tagName"
        />
      </div>
      <div class="flex gap-x-2 flex-wrap">
        <span
          v-for="{ _id, tagName } in selectedTags"
          :key="_id"
          class="bg-veryLight text-primaryGray text-xs p-2 rounded-md flex gap-3"
        >
          <span>{{ tagName }}</span>
          <button @click="removeSelectedTag(_id)">
            <img src="@/assets/svg/close-icon.svg" />
          </button>
        </span>
      </div>

      <div class="flex justify-center w-full mt-6">
        <trac-button
          class="w-1/2"
          id="clickToAdd"
          type="button"
          :disabled="loading || !selectedTags.length"
          @button-clicked="addTagToProduct"
        >
          ADD TAG(S)
        </trac-button>
      </div>
    </trac-modal>
  </div>
</template>

<script>
import tagMixin from "../../../src/views/products/Tags.mixin";
import CreateTag from "@/views/products/CreateTag.vue";

export default {
  components: {
    CreateTag,
  },
  mixins: [tagMixin],
  props: {
    tagsItems: {
      type: Array,
      default: () => [],
    },
  },
  // create a data property called 'tagList' and assign to props.tags
  data() {
    return {
      tagList: [],
      showAddModal: false,
      selectedTags: [],
    };
  },
  // create a computed property 'isEmpty' and return a boolean value based on the length of 'tagList'
  computed: {
    isEmpty() {
      return this.selectedTags.length === 0;
    },
  },
  methods: {
    toggleAddModal() {
      this.showAddModal = !this.showAddModal;
    },
    updateTags(selectedTagIds) {
      const newTags = selectedTagIds.map((_id) => {
        const tagName = this.tags.find((tag) => tag._id === _id).tagName;
        return {
          tagName,
          _id,
        };
      });

      this.selectedTags = [...newTags];
    },
    refetchSeletedTags() {
      console.log(this.tags, this.selectedTags);
      this.selectedTags = this.selectedTags.map((tag) =>
        this.tags.find((t) => t._id === tag._id)
      );
    },
    removeSelectedTag(_id) {
      this.selectedTags = this.selectedTags.filter((tag) => tag._id !== _id);
      this.$emit("update-tags", [...this.selectedTags]);
    },
    addTagToProduct() {
      this.$emit("update-tags", [...this.selectedTags]);
      this.toggleAddModal();
    },
    toggleSuccessEdit() {
      this.toggleAddTagSuccessModal();
      this.refetchSeletedTags();
      this.loadTags();
    },
  },
  watch: {
    tagsItems: {
      handler() {
        this.tagList = [...(this.tagsItems ?? [])];
        this.selectedTags = [...(this.tagsItems ?? [])];
      },
      immediate: true,
    },
  },
};
</script>

<style lang="postcss" scoped></style>
