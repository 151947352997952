<template>
  <div>
    <slot />

    <trac-modal v-if="show" @close="$emit('toggle-show')">
      <trac-tag
        :tag-id="tagId"
        :loading="loading"
        :supplied-tag="tagText"
        @edited-tag="$emit('update-tag', $event)"
        @added-tag="$emit('add-tag', $event)"
      />
    </trac-modal>
    <trac-modal v-if="showSuccess" @close="toggleSuccess">
      <div class="flex flex-col items-center gap-8 mb-6">
        <img src="@/assets/svg/success-2.svg" alt="" class="" />
        <p>{{ tagSuccessText }}</p>
        <trac-button
          class="w-3/5"
          id="clickToAdd"
          @button-clicked="toggleSuccess"
        >
          CLOSE
        </trac-button>
      </div>
    </trac-modal>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: false,
    },
    showSuccess: {
      type: Boolean,
      default: false,
    },
    tagSuccessText: {
      type: String,
      default: "",
    },
    tagText: {
      type: String,
      default: "",
    },
    tagId: {
      type: String,
      default: "",
    },
  },
  methods: {
    toggleSuccess() {
        this.$emit('toggle-success')
    }
  }
};
</script>
