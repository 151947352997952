<template>
  <trac-modal @close="$emit('close')">
    <div class="tag">
      <h1 class="mb-2 font-semibold">Add Modifiers</h1>

      <trac-multi-select-dropdown
        @input="updateModifier"
        title="Select modifier"
        :items="modifiers"
        :allow-initial-load="false"
        displayProp="title"
        :inputProps="{
          value: params.keyword,
          placeholder: 'Search modifier',
        }"
        :selectedItems="selectedModifiers"
        @change="params.keyword = $event"
      />

      <div class="flex gap-2 flex-wrap mt-2">
        <trac-capsule
          v-for="mod in selectedModifiers"
          :key="mod.id"
          class="cursor-pointer"
          :name="mod.title"
          @close="handleRemoveModifier(mod.id)"
        />
      </div>

      <button
        class="text-xs text-primaryBlue font-bold"
        @click="$router.push({ name: 'create-modifier' })"
      >
        Add New
      </button>

      <div class="mx-auto mt-6 w-1/2">
        <trac-button
          class="w-full"
          id="add-modifier"
          :disabled="!selectedModifiers.length"
          @button-clicked="$emit('update-modifiers', selectedModifiers)"
        >
          Add Modifier(s)
        </trac-button>
      </div>
    </div>
  </trac-modal>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "AddModifierToProduct",
  emits: ["close", "update-modifiers"],
  data() {
    return {
      modifiers: [],
      searchValue: "",
      params: {
        productType: "modifier",
        keyword: "",
      },
      selectedModifiers: [],
    };
  },
  methods: {
    ...mapActions(["FETCH_PRODUCTS_V2"]),
    async fetchModifiers() {
      this.loading = true;
      try {
        const res = await this.FETCH_PRODUCTS_V2({
          params: this.params,
          includeVariants: true,
          queryText: "modifier-with-variant",
        });

        if (res.status) {
          this.modifiers = res?.data?.products ?? [];
        }
      } catch (error) {
        eventBus.$emit("trac-alert", {
          message: error?.response?.data?.message,
        });
      } finally {
        this.loading = false;
      }
    },
    handleRemoveModifier(id) {
      this.selectedModifiers = this.selectedModifiers.filter(
        (mod) => mod.id !== id
      );
    },
    updateModifier(ids) {
      const modifiers = ids.map((id) =>
        this.modifiers.find((mod) => mod.id === id)
      );
      this.selectedModifiers = modifiers;
    },
  },
  watch: {
    "params.keyword": {
      handler() {
        this.fetchModifiers();
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
