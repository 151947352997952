import {
  ADD_DATA_TO_DB,
  CONNECT_TO_DB,
  GET_DB_DATA,
  GET_OBJECT_STORE,
  SAVE_CURRENT_BUSINESS_TO_OBJECT_STORE,
  SAVE_OBJECT_STORE,
} from "../../browser-db-config/indexedDB";
import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

function getSpecificStore(prod, storeId) {
  return prod.store_stock.find((ss) => ss.store_id === storeId) || {};
}

const state = {
  saleRes: null,
  tickets: null,
};

const getters = {
  GET_SALE_RES: (state) => state.saleRes,
};

const actions = {
  MAKE_OFFLINE_SALE: async function (context, payload) {
    // Open and get offline sales history
    // const notSyncedOfflineSalesDB = await CONNECT_TO_DB('not_synced_offline_sales', 1, 'not_synced_sales');
    // let notSyncedOfflineSales = await GET_DB_DATA(notSyncedOfflineSalesDB, 'not_synced_sales');
    let notSyncedOfflineSales = await GET_OBJECT_STORE(
      "not_synced_offline_sales",
      "not_synced_sales"
    );
    let offlineProducts = await GET_OBJECT_STORE(
      "offline_db_products",
      "products"
    );

    if (!notSyncedOfflineSales) {
      notSyncedOfflineSales = [];
    }

    notSyncedOfflineSales.push({
      ...payload,
      offline_date: new Date().toISOString().slice(0, -1),
      is_offline_data: true,
    });

    // Save assets to indexDB
    // await ADD_DATA_TO_DB(notSyncedOfflineSalesDB, 'not_synced_sales', notSyncedOfflineSales);
    await SAVE_OBJECT_STORE(
      "not_synced_offline_sales",
      "not_synced_sales",
      notSyncedOfflineSales
    );

    // Register Sync event in SW
    navigator.serviceWorker.ready.then((swReg) => {
      swReg.sync.register("not_synced_offline_sales");
    });

    // Update the db locally
    const salesMade = notSyncedOfflineSales || [];
    const offlineProductItems = (offlineProducts || {}).items || [];

    for (let i = 0; i < salesMade.length; i++) {
      // Get the sale
      const sale = salesMade[i];
      for (let j = 0; j < offlineProductItems.length; j++) {
        // get a product from products
        const offlineProduct = offlineProductItems[j];
        sale.items.forEach((saleItem) => {
          if (offlineProduct.variation) {
            offlineProduct.varieties.forEach((variant) => {
              if (variant._id === saleItem._id) {
                variant.stock -= saleItem.quantity;
                getSpecificStore(variant, saleItem.store_id).stock -=
                  saleItem.quantity;
              }
            });
          } else {
            if (offlineProduct._id === saleItem._id) {
              offlineProduct.stock -= saleItem.quantity;
              getSpecificStore(offlineProduct, saleItem.store_id).stock -=
                saleItem.quantity;
            }
          }
        });
      }
    }

    await SAVE_OBJECT_STORE("offline_db_products", "products", {
      items: offlineProductItems,
      metaData: offlineProducts.metaData,
      status: true,
    });

    context.commit("SET_UP_SALE_RES", {
      data: payload,
      message: "sale added",
      status: true,
    });
  },
  MAKE_SALE: async function (context, payload) {
    const httpReq = {
      url: `/v1/sales/`,
      method: "POST",
      authRequest: true,
      body: payload,
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    context.commit("SET_UP_SALE_RES", httpRes);
  },
  MAKE_SALE_V2: async function (context, payload) {
    const httpReq = {
      url: `/v2/business/${GET_USER_BUSINESS_ID()}/sales`,
      method: "POST",
      authRequest: true,
      body: payload,
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    return httpRes;
  },
  SEND_RECEIPT: async function (context, payload) {
    const httpReq = {
      url: `/v1/sales/${payload.sale._id}/receipts/?email=${payload.email}`,
      method: "POST",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    context.commit("SET_UP_SALE_RES", httpRes);
  },
  FETCH_UNPAID_SALES: async function (context, payload) {
    const httpReq = {
      url: `/v1/sales/businesses/${GET_USER_BUSINESS_ID()}/store/${
        payload._id
      }/unpaid/`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    context.commit("SET_UP_SALE_RES", httpRes);
    return httpRes;
  },
  UPDATE_SALE: async function (context, payload) {
    const httpReq = {
      url: `/v1/sales/${
        payload.saleID
      }/businesses/${GET_USER_BUSINESS_ID()}/payment/${payload.payment_method}`,
      method: "PATCH",
      authRequest: true,
      body: payload.body,
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    context.commit("SET_UP_SALE_RES", httpRes);
  },
};

const mutations = {
  SET_UP_SALE_RES: (state, data) => {
    state.saleRes = data;
  },
  SET_CURRENT_TICKET: (state, data) => {
    state.tickets = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
