import { Network } from '@capacitor/network';
import appState, { SWDataObservable, cleanSlate } from './offline.store';
import { hasMetaRecord, hasSyncStatus } from './falseDestination';
import OfflineManager from './index';
import { eventBus } from '../main';
import { GetOfflineConfigSettings, ModuleConfig } from './config';
import { GET_USER_BUSINESS_ID } from '../browser-db-config/localStorage';
import { setTimeout } from 'core-js';
import { batchSyncCheck } from './batchSync';
import { isEqual } from 'lodash';
import * as Sentry from '@sentry/vue';
import FallBackTracker from './fallBackTracker';
import {
  shouldBeString,
  validateArguments,
  displayErrors,
} from './fxnArgumentValidator';
import { checkToRefreshToken } from './refreshToken';
export default {
  install(Vue, { isProduction }) {
    Vue.prototype.$isAppOnline = true;
    Vue.prototype.$cacheManifest = [];
    Vue.prototype.$timeoutList = [];
    Vue.prototype.$SWDataObservable = Vue.observable({ data: null });
    Vue.prototype.$isProduction = isProduction;
    Vue.prototype.$metaDataHolder = null;
    Vue.prototype.$internalToken = '';
    Vue.mixin({
      //   created() {
      //     window.scrollTo(0, 0);
      //   },
      //   beforeDestroy() {
      //     window.scrollTo(0, 0);
      //   },
      data() {
        return {
          SWData: '',
          dualSyncStatus: null, // 'syncing', 'failed', 'done',
          syncManifest: null,
        };
      },
      watch: {
        SWData(newValue, oldValue) {
          // debugger
          // TODO: find out why this is being fired twice (duplicate firing in SW sendMessage?)
          // to prevent this watch firing twice or unless that value has truly changed
          // console.log('SWData params: NewValue: ' + JSON.stringify(newValue) + ' Oldvalue: ' + JSON.stringify(oldValue))
          if (!isEqual(newValue, oldValue)) {
            console.log('GLOBAL -> SWData: ', newValue);
            if (typeof newValue.data === 'string') {
              /* NOTE: Disabled for now. Feedback was too many messages from the SW*/
              // eventBus.$emit("trac-alert", {
              //     message: `Global: ${newValue.data}`,
              // });
            }
            if (newValue.type === 'error') {
              console.warn('errors tracked from SW: ', newValue);
              if (
                newValue.options.errorType === 'text' &&
                Vue.prototype.$isProduction
              ) {
                // Sentry.captureMessage(newValue.data);
                Vue.sentryCaptureMessage(newValue.data);
              } else if (
                newValue.options.errorType === 'event' &&
                Vue.prototype.$isProduction
              ) {
                // Sentry.captureException(newValue.data)
                Vue.sentryCaptureException(newValue.data);
              }
            }
            if (
              newValue.type === 'metadata' &&
              !isEqual(newValue, Vue.prototype.$metaDataHolder)
            ) {
              Vue.prototype.$metaDataHolder = newValue;
              console.log('metaData postMessage Detected: ', newValue);
              // save the metaData
              let ofm = this.$GlobalOfflineManager;
              if (ofm && ofm instanceof OfflineManager) {
                ofm
                  .saveMetaData(newValue.moduleName, newValue.data)
                  .then((data) => {
                    console.log('meta data saved from SW via App: ', data);
                    const fallbackMetaData = new FallBackTracker({
                      BusinessID: data.extra.businessID,
                    });
                    fallbackMetaData.saveMetaData(
                      data.extra.moduleName,
                      data.extra.metaData
                    );
                  });
              } else {
                Vue.sentryCaptureMessage(
                  'ofm = this.$GlobalOfflineManager not defined/assigned before being called'
                );
              }
            }
            if (
              newValue.moduleName &&
              typeof newValue.moduleName === 'string'
            ) {
              console.log(
                `dualSync Completion check in Plugin.js and module "${newValue.moduleName}" fired!`
              );
              cleanSlate.moduleList[newValue.moduleName] = false;
            }
            if (newValue.type === 'syncmonitor') {
              this.syncManifest = newValue.data;
              SWDataObservable.data = newValue.data;
              console.log('SyncMonitor Update:', SWDataObservable.data);
            }
          } else {
            // if (newValue.type === 'syncmonitor') {
            //     this.syncManifest = newValue.data
            //     Vue.prototype.$SWDataObservable.data = newValue.data
            //     console.log('sending to $SWDataObservable:', this.$SWDataObservable)
            // }
            console.warn(
              'SWData is same. NewValue: ' + newValue + ' Oldvalue: ' + oldValue
            );
          }
        },
      },
      computed: {
        isOnline() {
          console.log('computed from plugin: ', appState.online);
          return appState.online;
        },
        getTimeoutList() {
          return Vue.prototype.$timeoutList;
        },
        supportsCache() {
          return 'caches' in window;
        },
        supportSW() {
          return 'serviceWorker' in navigator;
        },
        SWUpdateAvailable() {
          return this.$SWUpdate;
        },
        cacheManifest() {
          return Vue.prototype.$cacheManifest;
        },
        // GlobalOfflineManager() {
        //     return this.$GlobalOfflineManager
        // }
      },
      // Added event listner for updates from the service worker
      // when entering a page
      beforeRouteEnter(to, from, next) {
        next((vm) => {
          console.log(
            'Before entering a page',
            vm.$el,
            'SWController:',
            vm.$SWController,
            vm
          );
          document.addEventListener('SWUpdate', vm.handleSWEvent);
        });
      },
      // removes event listener when leaving a page
      beforeRouteLeave(to, from, next) {
        document.removeEventListener('SWUpdate', this.handleSWEvent);
        console.log('Custom Event Removed from', this.$el);
        next();
      },
      methods: {
        clearAllPeriodicSyncInstructions() {
          console.log(
            'timeoutList before clearing',
            Vue.prototype.$timeoutList.map((item) => item)
          );
          Vue.prototype.$timeoutList.forEach((timeoutObject) => {
            clearTimeout(timeoutObject);
          });
          Vue.prototype.$timeoutList = [];
          console.log(
            'timeoutList after clearing out: ',
            Vue.prototype.$timeoutList,
            this.$timeoutList
          );
        },
        handleSWEvent(event) {
          // console.log('SWController.Plugin.js -> handleSWEvent', event.detail)
          this.SWData = event.detail;
        },
        isACompletedDualSyncEvent(EventObject = {}, moduleName = '') {
          return (
            EventObject.type === 'dualsync' &&
            EventObject.moduleName === moduleName
          );
        },
        isAProgressSyncEvent(EventObject = {}, moduleName = '') {
          return (
            EventObject.type === 'progress' &&
            EventObject.data.name === moduleName
          );
        },
        isADownloadOnlySyncEvent(EventObject = {}, moduleName = '') {
          return (
            EventObject.type === 'downloadonly' &&
            EventObject.moduleName === moduleName
          );
        },
        resetDualSyncDialog() {
          this.progress = {
            name: '',
            done: '',
            total: '',
            type: '',
            error: '',
          };
        },
        globalToast(toastMessage = null) {
          if (toastMessage && typeof toastMessage === 'string') {
            eventBus.$emit('trac-alert', { message: toastMessage });
          } else {
            console.error(
              'globalToast(): toastMessage parameter is null or not a string. Supplied parameter was: ',
              toastMessage
            );
          }
        },
        doBackgroundSync(
          modulename = null,
          lastUpdate = 0,
          params = null,
          metaData = null
        ) {
          console.log('moduleConfig: ', ModuleConfig);
          const moduleConfig = ModuleConfig[modulename];
          const batchSyncUrl = batchSyncCheck(metaData);
          if (
            moduleConfig &&
            moduleConfig.syncDetail &&
            typeof moduleConfig.syncDetail === 'function'
          ) {
            const moduleSyncDetails = moduleConfig.syncDetail(
              lastUpdate,
              params
            );
            console.log(
              `moduleSyncDetail -> ${modulename} `,
              moduleSyncDetails
            );
            moduleSyncDetails.EVENT_PAYLOAD.metaData = metaData;
            if (
              Vue.prototype.$internalToken &&
              typeof Vue.prototype.$internalToken === 'string'
            ) {
              console.log(
                `adding refreshed token to doBackgroundSync -> ${modulename} -> ${Vue.prototype.$internalToken}`
              );
              moduleSyncDetails.EVENT_PAYLOAD.authToken =
                Vue.prototype.$internalToken;
            }
            // debugger

            // // is this module sync a batched process?
            // if (moduleSyncDetails.EVENT_PAYLOAD.isBatchedProcess) {
            //     // batchSyncUrl(): Do we need to continue?
            //     // either returns a url string or false
            //     if(batchSyncUrl) {
            //         console.log(`batchSyncUrl for ${modulename}`, batchSyncUrl)
            //         moduleSyncDetails.EVENT_PAYLOAD.moduleGETAPIEndpoint = batchSyncUrl
            //         // send details to SW
            //         this.$SendMessageToSW(moduleSyncDetails)
            //     } else {
            //         // do nothing.
            //         console.warn(`batchSyncUrl check for ${modulename} is ${batchSyncUrl}, and so will not fire`)
            //     }
            // } else {
            //     // if not a batched process, send details to SW
            //     this.$SendMessageToSW(moduleSyncDetails)
            // }

            if (batchSyncUrl) {
              console.log(`batchSyncUrl for ${modulename}`, batchSyncUrl);
              moduleSyncDetails.EVENT_PAYLOAD.moduleGETAPIEndpoint =
                batchSyncUrl;
            }
            console.log(
              `doBackgroundSync for ${modulename}`,
              moduleSyncDetails
            );
            // debugger
            this.$SendMessageToSW(moduleSyncDetails);
          } else {
            throw new Error(
              `SWControllerPlugin -> doBackgroundSync: supplied modulename = '${modulename}' does not exist (or have syncDetail()) in config`
            );
          }
        },
        validMetaData(businessID, moduleName, suppliedMetaData = {}) {
          let isValid = true;
          if (
            suppliedMetaData.businessID === businessID &&
            suppliedMetaData.modulename === moduleName
          ) {
            isValid = true;
          } else {
            isValid = false;
          }
          return isValid;
        },
        async requestSync(
          tableName = null,
          options = { periodic: false, params: null, frequency: 0 }
        ) {
          const businessId = GET_USER_BUSINESS_ID();
          let ofm = this.$GlobalOfflineManager;
          console.warn(`firing requestSync:`, [tableName, options]);
          const [valid, errors] = validateArguments([
            shouldBeString(tableName, 'tablename'),
            shouldBeString(businessId, 'businessId'),
            ofm instanceof OfflineManager ||
              'ofm is not an instance of OfflineManager',
          ]);

          if (valid) {
            let status = false;
            let syncManifest = SWDataObservable.data;

            // cleanSlate.moduleList['customers'] = true  // for testing mutations
            // debugger
            // console.log('ofm: ', ofm, this.$GlobalOfflineManager)
            // get metaData for module
            let metaData = await ofm.getMetaData(tableName);
            const isValid = this.validMetaData(businessId, tableName, metaData);
            if (isValid) {
              console.log(`metaData from ${tableName} is valid: `, metaData);
            } else {
              const fallbackMetaData = new FallBackTracker({
                BusinessID: businessId,
              });
              metaData =
                fallbackMetaData.getFallBackMetaData(tableName) || null;
              console.warn(
                `metaData from ${tableName} is NOT valid. Switching to fallback:`,
                metaData
              );
            }
            // debugger
            // get last timestamp. default is 0 if non existent
            const lastUpdate = metaData ? metaData.lastUpdate || 0 : 0;
            console.log(`requestSync -> metaData for ${tableName}`, metaData);
            console.log(
              `requestSync -> lastUpdate for ${tableName}`,
              lastUpdate
            );
            const onlineCheck = appState.online;
            console.log('online check: ', onlineCheck);
            // debugger
            // only run if internet is working
            if (onlineCheck) {
              if (metaData) {
                if (
                  metaData.syncStatus === 'syncing' &&
                  (syncManifest || {})[tableName]
                ) {
                  status = 'syncing'; // let user know sync is in progress
                  console.warn(
                    `requestSync() - ${tableName} - encountered ongoing sync. will check again at next interval: ${JSON.stringify(
                      metaData
                    )}`
                  );
                  // debugger
                } else {
                  // Update metaData with syncStatus = 'syncing'
                  // since metaData exists.
                  metaData.syncStatus = 'syncing';
                  status = 'syncing'; // let user know sync is in progress
                  await ofm.saveMetaData(tableName, metaData);
                  // do actual background sync
                  this.doBackgroundSync(
                    tableName,
                    lastUpdate,
                    options.params,
                    metaData
                  );
                }
              } else {
                // set a flag indicating this is a cleanSlate. this will be
                // used in other pages to determine if to show anything other
                // than the sync dialog

                console.warn(
                  `requestSync: no metaData captured for ${tableName}. Initiating a cleanSlate flag for this.`
                );

                cleanSlate.moduleList[tableName] = true;

                // set metaData to only {dualSync: 'syncing'}
                // as no metaData exists before now
                status = 'syncing'; // let user know sync is in progress
                metaData = { syncStatus: 'syncing' };
                await ofm.saveMetaData(tableName, metaData);
                // do actual background sync.
                this.doBackgroundSync(
                  tableName,
                  lastUpdate,
                  options.params,
                  metaData
                );
              }
            } else {
              console.warn(
                'No internet detected. Will try again after interval period'
              );
            }

            // get settings for module
            // const settings = GetOfflineConfigSettings(tableName)
            // console.log(`requestSync for ${tableName}: `, settings)

            // if periodic sync has been requested, honour it
            if (options.periodic) {
              console.warn(
                `requestSync -> periodic sync is ${options.periodic}. Will repeat at interval`
              );
              // use frequency from settings to set a delay
              // before calling itself again
              if (!isNaN(options.frequency) && options.frequency) {
                // debugger
                console.warn(
                  `requestSync -> '${tableName}' Will repeat in ${
                    options.frequency / 60000
                  } mins`
                );
                const recursiveDelay = setTimeout(() => {
                  this.requestSync(tableName, {
                    periodic: options.periodic,
                    frequency: options.frequency,
                  });
                  clearTimeout(recursiveDelay);
                }, options.frequency);
              }
            } else {
              console.warn(
                `requestSync -> periodic sync is ${options.periodic}. will not repeat`
              );
            }
            // destroy the offline manager instance
            ofm = null;

            this.dualSyncStatus = status;
            return status;
          } else {
            throw new Error(
              `requestSync: ` +
                displayErrors(errors) +
                'Supplied Arguments: ' +
                arguments
            );
          }
        },
        getFilesForCaching() {
          // const websiteUrlKey = window.location.origin
          // // .filter(file => file.name.search(websiteUrlKey) > -1)
          // const filterAcceptableFiles = (valueString = '') => {
          //     const isJavascriptFile = valueString.search('/js/') > -1
          //     const isImageFile = valueString.search('/img/') > -1
          //     const isCssFile = valueString.search('/css/') > -1
          //     let matches = (isJavascriptFile || isImageFile || isCssFile) || false
          //     return matches
          // }
          // const fullList = performance.getEntriesByType('resource').filter(file => file.name.search(websiteUrlKey) > -1 && filterAcceptableFiles(file.name)).map(file => file.name.replace(websiteUrlKey, ""))
          // let distFiles = [...new Set(fullList)]
          // // .map(file => file.name)
          // console.log(`Performance Entries using ${websiteUrlKey}:`, distFiles)
          // const cacheName = 'traction-cache-v1'
          // const appShell = [
          // '/',
          // 'index.html',
          // // '/img/otherLogo.25ea15d2.svg',
          // 'https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500&display=swap'
          // ]
          // const allAssets = [...appShell, ...distFiles]

          const cacheName = 'traction-install-time-v1';
          const allAssets = this.$cacheManifest;
          console.log('Cache File routine enabled:', allAssets);
          return caches
            .delete(cacheName)
            .then(() => {
              return caches.open(cacheName);
            })
            .then((cache) => {
              console.log(`Populating new content for cache ${cacheName}`);
              return cache.addAll(allAssets);
            })
            .catch((err) => {
              throw new Error('Error Caching files: ' + err);
            });
          // caches
          // this.$SendMessageToSW({
          //     EVENT_PAYLOAD: {
          //         files: distFiles
          //     },
          //     EVENT_TYPE: 'CACHE_FILES'
          // });
        },
        async IsAppOnlineRightNow() {
          return await Network.getStatus();
        },
        async NetworkCheck() {
          const status = await Network.getStatus();
          appState.online = status.connected;
          console.log('Internet status on load: ', status.connected);
          return appState.online;
        },
        NetworkCheckListener() {
          Network.addListener('networkStatusChange', (status) => {
            console.log('Network status changed', status);
            // this.$set(this, "$isAppOnline", status.connected)
            appState.online = status.connected;
          });
        },
        async checkForSwUpdateOnPageEnter() {
          const registration = await navigator.serviceWorker.getRegistration();
          const needsUpdating = registration.waiting ? true : false;
          Vue.prototype.$SWUpdate = needsUpdating;
          this.$SWUpdate = needsUpdating;
          console.log('checkForSwUpdateOnPageEnter()', registration.waiting);
        },
        async upgradeSW() {
          const registration = await navigator.serviceWorker.getRegistration();
          console.log('SWController before update:', registration);
          registration.waiting.postMessage({
            EVENT_PAYLOAD: {},
            EVENT_TYPE: 'UPDATE_SW',
          });
        },
      },
    });
    Vue.prototype.$SWController = null;
    Vue.prototype.$SWMessage = null;
    Vue.prototype.$GlobalOfflineManager = null;
    Vue.prototype.$SWUpdate = false;
    Vue.prototype.$RegisterSW = function () {
      if ('serviceWorker' in navigator) {
        // Register a service worker hosted at the root of the site
        navigator.serviceWorker.register('./service-worker.js').then(
          (registration) => {
            Vue.prototype.$SWController =
              registration.installing ||
              registration.waiting ||
              registration.active;
            console.info(
              'SWController.Plugin.js -> Vue.prototype.$SWController',
              Vue.prototype.$SWController
            );

            // Check for subsequent loads (after sw new ver is published)
            // if sw hasn't been updated yet. For first time check,
            // refer to App.vue -> checkforUpgrade
            // if (registration.waiting) {
            //     // for subsequent loading of the site, where
            //     // new service worker is waiting
            //     console.log('SW is in waiting state')
            //     Vue.prototype.$SWUpdate = true
            // }
          },
          (error) => {
            console.log('Service worker registration failed:', error);
          }
        );
        //listen for the latest sw
        navigator.serviceWorker.addEventListener('controllerchange', () => {
          Vue.prototype.$SWController = navigator.serviceWorker.controller;
          console.log(
            'Controller Change: ',
            navigator.serviceWorker.controller
          );
          window.location.reload();
        });
        //listen for messages from the service worker
        navigator.serviceWorker.addEventListener(
          'message',
          Vue.prototype.$onSWMessage
        );
      } else {
        console.log('Service workers are not supported.');
      }
    };

    Vue.prototype.$requestMonitoring = function (
      options = { repeat: true, interval: 5000 }
    ) {
      const SW_PAYLOAD = {
        EVENT_PAYLOAD: {},
        EVENT_TYPE: 'MONITOR_SYNC',
      };
      this.$SendMessageToSW(SW_PAYLOAD);
      console.log('sending request to SW for all activity');
      if (options.repeat) {
        const delayTimeout = setTimeout(() => {
          Vue.prototype.$requestMonitoring();
        }, options.interval);
      }
    };

    Vue.prototype.$onSWMessage = function (message) {
      // console.log('Message from SW: ', message)
      if (message.data.type === 'cachemanifest') {
        console.log('recieved cacheManifest: ', message.data.data);
        Vue.prototype.$cacheManifest = message.data.data;
      }
      Vue.prototype.$SWMessage = message;
      const SWData = new CustomEvent('SWUpdate', {
        detail: message.data,
      });
      document.dispatchEvent(SWData);
    };

    Vue.prototype.$getSyncSettings = async function () {
      const settings =
        (await this.$GlobalOfflineManager.getAll('syncsettings')) || [];
      let loadedSettings = [];
      const hasSetting = settings.length > 0;
      const hasNoFalseEntry = settings.every(
        (entry) =>
          !hasSyncStatus(entry, 'syncsettings') &&
          !hasMetaRecord(entry, 'syncsettings')
      );
      console.log('outcome of syncsettings hasNoFalseEntry: ', hasNoFalseEntry);
      // debugger
      if (hasSetting && hasNoFalseEntry === true) {
        loadedSettings = settings;
      } else {
        if (!hasNoFalseEntry) {
          console.warn('False Destination on SyncSettings: ', settings);
        }
        loadedSettings = Object.keys(ModuleConfig).map((moduleName) => {
          return {
            name: moduleName,
            frequency: 300000,
          };
        });
      }
      console.log('returned from getSyncSettings: ', loadedSettings);
      return loadedSettings;
    };

    Vue.prototype.$beginPeriodicSync = async function () {
      const token = await checkToRefreshToken().catch((err) => {
        Vue.sentryCaptureException(new Error(err));
      });
      console.log('$beginPeriodicSync -> token check before sending:', token);
      Vue.prototype.$internalToken = token;
      const settings = await this.$getSyncSettings();
      const doRequest = async (
        currentIndex = 0,
        totalModules = 0,
        moduleArray = []
      ) => {
        // we are checking synced settings for instances where the value was updated
        // in between syncs (by going to the Sync Settings Page)
        const checkedSettings = await Vue.prototype.$getSyncSettings();
        console.log(
          `now firing sync for module: ${moduleArray[currentIndex].name} ${
            moduleArray[currentIndex].frequency / (1000 * 60)
          } mins`
        );
        this.requestSync(moduleArray[currentIndex].name, {
          periodic: true,
          frequency: checkedSettings[currentIndex].frequency,
        });
        if (currentIndex < totalModules - 1) {
          const recursiveTimeout = setTimeout(() => {
            currentIndex++;
            doRequest(currentIndex, totalModules, moduleArray);
          }, 3000);
          // add each setTimeout function to a global list we can
          // track
          this.$timeoutList.push(recursiveTimeout);
        } else {
          // do nothing
        }
      };

      // debugger
      if (settings && settings.length > 0 && Array.isArray(settings)) {
        // let maxAtaTime = 2
        let counter = 0;
        doRequest(counter, settings.length, settings);
        console.log('$beginPeriodicSync initiated:');
      }
    };
    Vue.prototype.$SendMessageToSW = function (message) {
      navigator.serviceWorker.ready.then((worker) => {
        worker.active.postMessage(message);
      });
    };
    Vue.prototype.$InstantiateGlobalOfflineManager = function (
      BusinessID = null
    ) {
      console.log('Instantiating GlobalOffline Manager using ', BusinessID);
      if (BusinessID) {
        Vue.prototype.$GlobalOfflineManager = new OfflineManager({
          BusinessId: BusinessID,
        });
      } else {
        throw new Error(
          'BusinessID not provided for $InstantiateGlobalOfflineManager()'
        );
      }
    };
    Vue.prototype.$ClearGlobalOfflineManager = function () {
      Vue.prototype.$GlobalOfflineManager = null;
    };
  },
};
