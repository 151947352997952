<template>
  <div class="flex flex-row bg-white items-center rounded justify-between">
    <img src="@/assets/svg/Search_icon.svg" class="mr-3" />
    <span class="text-xs font-light text-primaryGray w-full">
      <input
        class="w-full py-2 outline-none uppercase font-bold"
        :class="className"
        type="text"
        name=""
        id=""
        :value="value"
        :placeholder="placeholder"
        @input="setInput($event.target.value)"
        v-bind="$attrs"
      />
    </span>
  </div>
</template>

<script>
export default {
  name: 'SearchInput',
  inheritAttrs: false,
  emits: ['input'],
  props: {
    value: {
      type: String,
    },
    delay: {
      type: Number,
      default: 1000,
    },
    placeholder: {
      type: String,
      default: 'Search',
    },
    className: {
      type: [String, Object, Array],
      default: '',
    },
  },
  data() {
    return {
      timeout: null,
    };
  },
  methods: {
    setInput(value) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.$emit('input', value);
      }, this.delay);
    },
  },
};
</script>

<style lang="scss" scoped></style>
