import Vue from 'vue';
import VueRouter from 'vue-router';
import {
  GET_LOCAL_DB_DATA,
  GET_ALL_AUTH_USERS,
  ITERATEALL_ALL_AUTH_USERS,
  SAVE_LOCAL_DB_DATA,
  GET_CURRENT_AUTH_USER,
  GET_USER_DATA,
} from '../browser-db-config/localStorage';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const checkAdminRole = (to, from, next) => {
  const authRole = GET_USER_DATA().role
    ? GET_USER_DATA().role.toLowerCase()
    : '';

  if (authRole !== 'admin') {
    return false;
  } else {
    next();
  }
};

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login'),
    meta: {
      hideForAuth: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('../views/ForgotPassword'),
    meta: {
      hideForAuth: true,
    },
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('../views/Signup'),
    meta: {
      hideForAuth: true,
    },
  },
  {
    path: '/view-invoice',
    name: 'ViewInvoiceWithoutAuth',
    component: () =>
      import('../views/invoices/invoice-view/viewInvoiceDetails'),
  },
  {
    path: '/signup/fcmb/:email',
    name: 'FcmbSignUp',
    component: () => import('../views/FcmbSignup'),
    meta: {
      hideForAuth: true,
    },
  },
  {
    path: '/fcmb-login',
    name: 'FcmbLogin',
    component: () => import('../views/FcmbLogin'),
    meta: {
      hideForAuth: true,
    },
  },
  {
    path: '/components',
    name: 'Components',
    // route level code-splitting
    // this generates a separate chunk (components.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "components" */ '../views/Components.vue'),
  },
  {
    path: '/',
    component: Home,
    redirect: { name: 'Dashboard' },
    children: [
      // ======================================================================   Dashboard
      {
        path: 'gwt',
        name: 'GWT',
        component: () => import('../views/GWT'),
      },
      {
        path: 'cardMonitoring',
        name: 'CardMonitoring',
        component: () => import('../views/CardMonitoring'),
      },
      {
        path: 'security',
        redirect: { name: 'Security' },
        component: () => import('../views/security/Security.vue'),
        path: 'cardMonitoring',
        name: 'CardMonitoring',
        component: () => import('../views/CardMonitoring'),
      },
      {
        path: 'security',
        redirect: { name: 'Security' },
        component: () => import('../views/security/Security.vue'),
        children: [
          {
            path: '',
            name: 'Security',
            component: () => import('../views/security/SecurityAuthLogin.vue'),
          },
          {
            path: 'dashboard',
            name: 'SecurityDashboard',
            component: () => import('../views/security/SecurityDashboard.vue'),
          },
          {
            path: 'devices',
            name: 'SecurityDevices',
            component: () =>
              import('../views/security/WhitelistedDeviceList.vue'),
          },
          {
            path: 'otptoggle',
            name: 'SecurityOTPSetting',
            component: () =>
              import('../views/security/SecurityOTPToggleSetting.vue'),
          },
        ],
      },
      {
        path: 'noona',
        redirect: { name: 'Noona' },
        component: () => import('../views/noona/Noona.vue'),
        children: [
          {
            path: '',
            name: 'Security',
            component: () => import('../views/security/SecurityAuthLogin.vue'),
          },
          {
            path: 'dashboard',
            name: 'SecurityDashboard',
            component: () => import('../views/security/SecurityDashboard.vue'),
          },
          {
            path: 'devices',
            name: 'SecurityDevices',
            component: () =>
              import('../views/security/WhitelistedDeviceList.vue'),
          },
          {
            path: 'otptoggle',
            name: 'SecurityOTPSetting',
            component: () =>
              import('../views/security/SecurityOTPToggleSetting.vue'),
          },
        ],
      },
      {
        path: 'noona',
        redirect: { name: 'Noona' },
        component: () => import('../views/noona/Noona.vue'),
        children: [
          {
            path: '',
            name: 'Noona',
            component: () => import('../views/noona/Login.vue'),
          },
          {
            path: 'appointments',
            name: 'Appointments',
            component: () => import('../views/noona/TabbedDashboard.vue'),
            children: [
              {
                path: '',
                name: 'NoonaAppointments',
                components: {
                  default: () => import('../views/noona/AppointmentLink.vue'),
                  tabbedView: () =>
                    import('../views/noona/AppointmentLink.vue'),
                },
              },
              {
                path: '/noona-transactions',
                name: 'NoonaTransactions',
                components: {
                  // default: () => import('../views/noona/Transactions.vue'),
                  tabbedView: () => import('../views/noona/Transactions.vue'),
                },
              },
            ],
          },
          {
            path: 'store-settings',
            name: 'StoreSettings',
            component: () => import('../views/noona/StoreSettings.vue'),
          },
          {
            path: 'booking-settings',
            name: 'BookingSettings',
            component: () => import('../views/noona/BookingSettings.vue'),
          },
          {
            path: 'transactions',
            name: 'Transaction',
            component: () => import('../views/noona/Transactions.vue'),
          },
          {
            path: 'login',
            name: 'NoonaLogin',
            component: () => import('../views/noona/Login.vue'),
          },
        ],
      },
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('../views/dashboard_v2/Dashboard'),
      },
      {
        path: 'tabbed-integrations',
        name: 'TabbedIntegrations',
        components: {
          default: () =>
            import('../views/settings/TabbedIntegrationSettings.vue'),
          tabbedView: () =>
            import('../views/settings/TabbedIntegrationSettings.vue'),
        },
        children: [
          {
            path: 'all-integrations',
            name: 'AllIntegrations',
            components: {
              default: () =>
                import('../views/settings/integrations/AllIntegrations.vue'),
              tabbedView: () =>
                import('../views/settings/integrations/AllIntegrations.vue'),
            },
          },
          {
            path: 'my-integrations',
            name: 'MyIntegrations',
            components: {
              default: () =>
                import('../views/settings/integrations/MyIntegrations.vue'),
              tabbedView: () =>
                import('../views/settings/integrations/MyIntegrations.vue'),
            },
          },
          {
            path: 'integration-connection',
            name: 'IntegrationsConnection',
            components: {
              default: () =>
                import('../views/settings/integrations/ConnectIntegration'),
              tabbedView: () =>
                import('../views/settings/integrations/ConnectIntegration'),
            },
          },
        ],
      },
      {
        path: 'add-device',
        name: 'AddDevice',
        component: () => import('../views/security/adddevice'),
        meta: {
          hideForAuth: true,
        },
      },
      {
        path: 'syncsettings',
        name: 'SyncSettings',
        component: () => import('../views/settings/SyncSettings'),
      },
      {
        path: 'settings',
        name: 'AppSettings',
        component: () => import('../views/settings/TabbedSettings.vue'),
        children: [
          {
            path: 'kycProfile',
            name: 'KycProfile',
            components: {
              default: () => import('../views/kyc/Kyc'),
              tabbedView: () => import('../views/kyc/Kyc'),
            },
            beforeEnter: (to, from, next) => {
              const authRole = GET_USER_DATA().role
                ? GET_USER_DATA().role.toLowerCase()
                : '';

              if (authRole !== 'admin') {
                return false;
              } else {
                next();
              }
            },
          },
          {
            path: 'sales-agent',
            name: 'SalesAgent',
            components: {
              default: () => import('../views/settings/SalesAgent'),
              tabbedView: () => import('../views/settings/SalesAgent'),
            },
            beforeEnter: checkAdminRole,
          },
          {
            path: 'company-info-disabled',
            name: 'CompanyInfoDisabled',
            components: {
              default: () => import('../views/settings/CompanyInfoDisabled'),
              tabbedView: () => import('../views/settings/CompanyInfoDisabled'),
            },
            beforeEnter: checkAdminRole,
          },
          {
            path: 'syncsettings',
            name: 'SyncSettings',
            components: {
              default: () => import('../views/settings/SyncSettings'),
              tabbedView: () => import('../views/settings/SyncSettings'),
            },
          },
          {
            path: 'all-stores',
            name: 'AllStores',
            components: {
              default: () => import('../views/stores/Stores'),
              tabbedView: () => import('../views/stores/Stores'),
            },
          },
          {
            path: 'add-store',
            name: 'AddStore',
            components: {
              default: () => import('../views/stores/AddStore'),
              tabbedView: () => import('../views/stores/AddStore'),
            },
          },
          {
            path: 'user-roles',
            name: 'UserRoles',
            components: {
              default: () => import('../views/settings/UserRoles'),
              tabbedView: () => import('../views/settings/UserRoles'),
            },
            beforeEnter: checkAdminRole,
          },
          {
            path: 'employee-menu',
            name: 'EmployeeMenu',
            // component: () => import('../views/settings/EmployeeMenu'),
            components: {
              default: () => import('../views/settings/EmployeeMenu'),
              tabbedView: () => import('../views/settings/EmployeeMenu'),
            },
            beforeEnter: checkAdminRole,
          },
          {
            path: 'user-settings',
            name: 'UserSettings',
            components: {
              default: () => import('../views/settings/Users'),
              tabbedView: () => import('../views/settings/Users'),
            },
            beforeEnter: checkAdminRole,
          },
          {
            path: 'payment-methods',
            name: 'PaymentMethod',
            components: {
              default: () => import('../views/settings/PaymentMethod'),
              tabbedView: () => import('../views/settings/PaymentMethod'),
            },
            beforeEnter: checkAdminRole,
          },
          {
            path: 'integrations',
            name: 'Integrations',
            components: {
              default: () =>
                import('../views/settings/integrations/Integrations'),
              tabbedView: () =>
                import('../views/settings/integrations/Integrations'),
            },
          },
        ],
      },
      {
        path: 'support',
        name: 'Support',
        component: () => import('../views/TabbbedSupport.vue'),
        children: [
          {
            path: 'user-guides',
            name: 'UserGuides',
            components: {
              default: () => import('../views/UserGuides/UserGuides'),
              tabbedView: () => import('../views/UserGuides/UserGuides'),
            },
          },
          {
            path: 'support-form',
            name: 'SupportForm',
            components: {
              default: () => import('../views/UserGuides/SupportForm'),
              tabbedView: () => import('../views/UserGuides/SupportForm'),
            },
          },
        ],
      },
      {
        path: 'tab-online-catalogue',
        name: 'TabOnlineCatalogue',
        component: () => import('../views/TabbedOnlineStore.vue'),
        children: [
          {
            path: 'online-catalogue',
            name: 'OnlineCatalogue',
            components: {
              default: () => import('../views/onlinecatalogue/OnlineCatalogue'),
              tabbedView: () =>
                import('../views/onlinecatalogue/OnlineCatalogue'),
            },
          },
          {
            path: 'orders',
            name: 'Orders',
            components: {
              default: () => import('../views/orders/Orders'),
              tabbedView: () => import('../views/orders/Orders'),
            },
          },
          // {
          //   path: '/orders',
          //   name: 'Orders',
          //   components: {
          //     default: () => import('../views/orders/Orders'),
          //     tabbedView: () => import('../views/orders/Orders'),
          //   },
          //   // component: () => import('../views/orders/Orders'),
          // },
          {
            path: 'order-details/:id',
            name: 'OrderDetails',
            components: {
              default: () => import('../views/orders/OrderDetails'),
              tabbedView: () => import('../views/orders/OrderDetails'),
            },
            // component: () => import('../views/orders/OrderDetails'),
          },
          {
            path: 'add-payment/:id',
            name: 'AddPayment',
            components: {
              default: () => import('../views/orders/AddPayment'),
              tabbedView: () => import('../views/orders/AddPayment'),
            },
            // component: () => import('../views/orders/AddPayment'),
          },
        ],
      },
      {
        path: 'productsinventory',
        name: 'ProductsTab',
        component: () => import('../views/products/TabbedProductInventory.vue'),
        children: [
          {
            path: 'myproducts',
            name: 'MyProducts',
            components: {
              default: () => import('../views/products/ProductsV2.vue'),
              tabbedView: () => import('../views/products/ProductsV2.vue'),
            },
          },
          {
            path: 'categoryenable',
            name: 'CategoryEnable',
            components: {
              default: () => import('../views/categories/categoryEnable.vue'),
              tabbedView: () =>
                import('../views/categories/categoryEnable.vue'),
            },
          },
          {
            path: 'categories',
            name: 'Categories',
            components: {
              default: () => import('../views/categories/CategoriesV2'),
              tabbedView: () => import('../views/categories/CategoriesV2'),
            },
          },
          {
            path: 'options',
            name: 'Options',
            components: {
              default: () => import('../views/products/Options/index.vue'),
              tabbedView: () => import('../views/products/Options/index.vue'),
            },
          },
          {
            path: 'add-product',
            name: 'AddProduct',
            components: {
              default: () => import('../views/products/AddProduct'),
              tabbedView: () => import('../views/products/AddProduct'),
            },
          },
          {
            path: 'edit-product/:id',
            name: 'EditProduct',
            components: {
              default: () => import('../views/products/AddProduct'),
              tabbedView: () => import('../views/products/AddProduct'),
            },
          },
          {
            path: '/categories-list',
            name: 'CategoriesList',
            components: {
              default: () => import('../views/categories/CategoriesList'),
              tabbedView: () => import('../views/categories/CategoriesList'),
            },
          },
          {
            path: 'modifiers',
            name: 'modifiers',
            components: {
              default: () => import('../views/Modifiers/index.vue'),
              tabbedView: () => import('../views/Modifiers/index.vue'),
            },
          },
          {
            path: 'tags',
            name: 'Tags',
            components: {
              default: () => import('../views/products/Tags.vue'),
              tabbedView: () => import('../views/products/Tags.vue'),
            },
          },
          // {
          //   path: 'stock-count',
          //   name: 'StockCount',
          //   component: {
          //     default: () => import('../views/inventory-control/stock-count/StockCount'),
          //     tabbedView: () => import('../views/inventory-control/stock-count/StockCount'),
          //   },
          //   // component: () =>
          //   //   import('../views/inventory-control/stock-count/StockCount'),
          // },
        ],
      },
      {
        path: 'inventory',
        name: 'InventoryTab',
        component: () => import('../views/products/TabbedInventory.vue'),
        children: [
          {
            path: 'myproducts',
            name: 'MyProducts',
            components: {
              default: () => import('../views/products/Products.vue'),
              tabbedView: () => import('../views/products/Products.vue'),
            },
          },
          {
            path: 'categoryenable',
            name: 'CategoryEnable',
            components: {
              default: () => import('../views/categories/categoryEnable.vue'),
              tabbedView: () =>
                import('../views/categories/categoryEnable.vue'),
            },
          },
          {
            path: 'categories',
            name: 'Categories',
            components: {
              default: () => import('../views/categories/Categories'),
              tabbedView: () => import('../views/categories/Categories'),
            },
          },
          {
            path: 'add-product',
            name: 'AddProduct',
            components: {
              default: () => import('../views/products/AddProduct'),
              tabbedView: () => import('../views/products/AddProduct'),
            },
          },
          {
            path: '/categories-list',
            name: 'CategoriesList',
            components: {
              default: () => import('../views/categories/CategoriesList'),
              tabbedView: () => import('../views/categories/CategoriesList'),
            },
          },
          {
            path: 'print-label',
            name: 'PrintLabel',
            components: {
              default: () => import('../views/inventory-control/PrintLabel'),
              tabbedView: () => import('../views/inventory-control/PrintLabel'),
            },
          },
          {
            path: 'inventory-transfer',
            name: 'InventoryTransfer',
            components: {
              default: () =>
                import(
                  '../views/inventory-control/inventory-transfer/InventoryTransfer'
                ),
              tabbedView: () =>
                import(
                  '../views/inventory-control/inventory-transfer/InventoryTransfer'
                ),
            },
          },
          // {
          //   path: '/inventory-transfer',
          //   name: 'InventoryTransfer',
          //   component: () =>
          //     import(
          //       '../views/inventory-control/inventory-transfer/InventoryTransfer'
          //     ),
          // },
          {
            path: '/create-transfer',
            name: 'CreateTransfer',
            components: {
              default: () =>
                import(
                  '../views/inventory-control/inventory-transfer/CreateTransfer'
                ),
              tabbedView: () =>
                import(
                  '../views/inventory-control/inventory-transfer/CreateTransfer'
                ),
            },
            // component: () =>
            //   import(
            //     '../views/inventory-control/inventory-transfer/CreateTransfer'
            //   ),
          },
          {
            path: '/transfer-detail',
            name: 'TransferDetails',
            components: {
              default: () =>
                import(
                  '../views/inventory-control/inventory-transfer/TransferDetails'
                ),
              tabbedView: () =>
                import(
                  '../views/inventory-control/inventory-transfer/TransferDetails'
                ),
            },

            // component: () =>
            //   import(
            //     '../views/inventory-control/inventory-transfer/TransferDetails'
            //   ),
          },
          {
            path: '/transfer-order',
            name: 'TransferOrder',
            components: {
              default: () => import('../views/inventory-control/TransferOrder'),
              tabbedView: () =>
                import('../views/inventory-control/TransferOrder'),
            },

            // component: () => import('../views/inventory-control/TransferOrder'),
          },
          {
            path: 'stock-count',
            name: 'StockCount',
            components: {
              default: () =>
                import('../views/inventory-control/stock-count/StockCount'),
              tabbedView: () =>
                import('../views/inventory-control/stock-count/StockCount'),
            },
          },
          {
            path: 'new-count',
            name: 'NewCount',
            components: {
              default: () =>
                import('../views/inventory-control/stock-count/NewCount'),
              tabbedView: () =>
                import('../views/inventory-control/stock-count/NewCount'),
            },
            // component: () =>
            //   import('../views/inventory-control/stock-count/NewCount'),
          },
          {
            path: 'category-count',
            name: 'CategoryCount',
            components: {
              default: () =>
                import('../views/inventory-control/stock-count/CategoryCount'),
              tabbedView: () =>
                import('../views/inventory-control/stock-count/CategoryCount'),
            },
            // component: () =>
            //   import('../views/inventory-control/stock-count/CategoryCount'),
          },
          {
            path: 'submit-count',
            name: 'SubmitCount',
            components: {
              default: () =>
                import('../views/inventory-control/stock-count/SubmitCount'),
              tabbedView: () =>
                import('../views/inventory-control/stock-count/SubmitCount'),
            },
            // component: () =>
            //   import('../views/inventory-control/stock-count/SubmitCount'),
          },
          {
            path: 'accept-count',
            name: 'AcceptCount',
            components: {
              default: () =>
                import('../views/inventory-control/stock-count/AcceptCount'),
              tabbedView: () =>
                import('../views/inventory-control/stock-count/AcceptCount'),
            },
            // component: () =>
            //   import('../views/inventory-control/stock-count/AcceptCount'),
          },
          {
            path: 'count-completed',
            name: 'CountCompleted',
            components: {
              default: () =>
                import('../views/inventory-control/stock-count/CountCompleted'),
              tabbedView: () =>
                import('../views/inventory-control/stock-count/CountCompleted'),
            },
            // component: () =>
            //   import('../views/inventory-control/stock-count/CountCompleted'),
          },
          {
            path: 'stock-adjustment',
            name: 'StockAdjustment',
            components: {
              default: () =>
                import(
                  '../views/inventory-control/stock-adjustment/StockAdjustment'
                ),
              tabbedView: () =>
                import(
                  '../views/inventory-control/stock-adjustment/StockAdjustment'
                ),
            },
          },
          {
            path: 'add-stock',
            name: 'AddStock',
            components: {
              default: () =>
                import('../views/inventory-control/stock-adjustment/AddStock'),
              tabbedView: () =>
                import('../views/inventory-control/stock-adjustment/AddStock'),
            },
            // component: () =>
            //   import('../views/inventory-control/stock-adjustment/AddStock'),
          },
          {
            path: 'remove-stock',
            name: 'RemoveStock',
            components: {
              default: () =>
                import(
                  '../views/inventory-control/stock-adjustment/RemoveStock'
                ),
              tabbedView: () =>
                import(
                  '../views/inventory-control/stock-adjustment/RemoveStock'
                ),
            },
            // component: () =>
            //   import('../views/inventory-control/stock-adjustment/RemoveStock'),
          },
          {
            path: 'add-stock-next',
            name: 'AddStockNext',
            components: {
              default: () =>
                import(
                  '../views/inventory-control/stock-adjustment/AddStockNext'
                ),
              tabbedView: () =>
                import(
                  '../views/inventory-control/stock-adjustment/AddStockNext'
                ),
            },

            // component: () =>
            //   import('../views/inventory-control/stock-adjustment/AddStockNext'),
          },
          {
            path: 'remove-stock-next',
            name: 'RemoveStockNext',
            components: {
              default: () =>
                import(
                  '../views/inventory-control/stock-adjustment/RemoveStockNext'
                ),
              tabbedView: () =>
                import(
                  '../views/inventory-control/stock-adjustment/RemoveStockNext'
                ),
            },
            // component: () =>
            //   import('../views/inventory-control/stock-adjustment/RemoveStockNext'),
          },
          {
            path: 'new-count',
            name: 'NewCount',
            components: {
              default: () =>
                import('../views/inventory-control/stock-count/NewCount'),
              tabbedView: () =>
                import('../views/inventory-control/stock-count/NewCount'),
            },
            // component: () =>
            //   import('../views/inventory-control/stock-count/NewCount'),
          },
          {
            path: 'category-count',
            name: 'CategoryCount',
            components: {
              default: () =>
                import('../views/inventory-control/stock-count/CategoryCount'),
              tabbedView: () =>
                import('../views/inventory-control/stock-count/CategoryCount'),
            },
            // component: () =>
            //   import('../views/inventory-control/stock-count/CategoryCount'),
          },
          {
            path: 'submit-count',
            name: 'SubmitCount',
            components: {
              default: () =>
                import('../views/inventory-control/stock-count/SubmitCount'),
              tabbedView: () =>
                import('../views/inventory-control/stock-count/SubmitCount'),
            },
            // component: () =>
            //   import('../views/inventory-control/stock-count/SubmitCount'),
          },
          {
            path: 'accept-count',
            name: 'AcceptCount',
            components: {
              default: () =>
                import('../views/inventory-control/stock-count/AcceptCount'),
              tabbedView: () =>
                import('../views/inventory-control/stock-count/AcceptCount'),
            },
            // component: () =>
            //   import('../views/inventory-control/stock-count/AcceptCount'),
          },
          {
            path: 'count-completed',
            name: 'CountCompleted',
            components: {
              default: () =>
                import('../views/inventory-control/stock-count/CountCompleted'),
              tabbedView: () =>
                import('../views/inventory-control/stock-count/CountCompleted'),
            },
            // component: () =>
            //   import('../views/inventory-control/stock-count/CountCompleted'),
          },
          {
            path: 'print-label',
            name: 'PrintLabel',
            components: {
              default: () => import('../views/inventory-control/PrintLabel'),
              tabbedView: () => import('../views/inventory-control/PrintLabel'),
            },
          },
        ],
      },
      {
        path: 'productsinventory/categories/:id',
        name: 'category',
        components: {
          default: () => import('../views/categories/Category'),
          tabbedView: () => import('../views/categories/Category'),
        },
      },
      {
        path: 'productsinventory/categories/:id/:sub_id',
        name: 'subcategory',
        components: {
          default: () => import('../views/categories/SubCategory/index.vue'),
          tabbedView: () => import('../views/categories/SubCategory/index.vue'),
        },
      },
      {
        path: 'productsinventory/create-modifier',
        name: 'create-modifier',
        components: {
          default: () => import('../views/Modifiers/CreateModifier/index.vue'),
          tabbedView: () =>
            import('../views/Modifiers/CreateModifier/index.vue'),
        },
      },
      {
        path: 'qa',
        name: 'QualityAssurance',
        component: () => import('../views/qa/offlinemanager.vue'),
      },
      {
        path: 'test',
        name: 'OfflineTest',
        component: () => import('../views/Test.vue'),
      },
      // ======================================================================   Loan
      {
        path: 'loan',
        component: () => import('../views/Loan/LoanRouter'),
        redirect: { name: 'LoanMain' },
        beforeEnter: (to, from, next) => {
          const authRole = GET_USER_DATA().role
            ? GET_USER_DATA().role.toLowerCase()
            : '';

          if (authRole !== 'admin') {
            return false;
          } else {
            next();
          }
        },
        children: [
          {
            path: '',
            name: 'LoanMain',
            component: () => import('../views/Loan/Main'),
          },
          {
            path: 'loan-landing',
            name: 'LoanLanding',
            component: () => import('../views/Loan/Landing.vue'),
          },
          {
            path: 'loan-request',
            name: 'LoanRequest',
            component: () => import('../views/Loan/LoanRequest.vue'),
          },
        ],
      },

      // ======================================================================   Kyc
      {
        path: 'kyc',
        component: () => import('../views/kyc/Main'),
        redirect: { name: 'kyc' },
        beforeEnter: (to, from, next) => {
          const authRole = GET_USER_DATA().role
            ? GET_USER_DATA().role.toLowerCase()
            : '';

          if (authRole !== 'admin') {
            return false;
          } else {
            next();
          }
        },
        children: [
          {
            path: '',
            name: 'Kyc',
            component: () => import('../views/kyc/Kyc'),
          },
          {
            path: 'verify-otp',
            name: 'VerifyOtp',
            component: () => import('../views/kyc/VerifyOtp'),
          },
          {
            path: 'verify-email',
            name: 'VerifyEmail',
            component: () => import('../views/kyc/VerifyEmail'),
          },
          {
            path: 'verify-phone',
            name: 'VerifyPhone',
            component: () => import('../views/kyc/VerifyPhone'),
          },
          {
            path: 'verify-phone-otp',
            name: 'VerifyPhoneOtp',
            component: () => import('../views/kyc/VerifyPhoneOtp'),
          },

          {
            path: 'add-bank-details',
            name: 'AddBankDetails',
            component: () => import('../views/kyc/AddBankDetails'),
          },
          {
            path: 'existing-phone-email',
            name: 'ExistingPhoneEmail',
            component: () => import('../views/kyc/ExistingPhoneEmail'),
          },
          {
            path: 'utility-bill',
            name: 'UtilityBill',
            component: () => import('../views/kyc/UtilityBill'),
            path: 'utility-bill',
            name: 'UtilityBill',
            component: () => import('../views/kyc/UtilityBill'),
          },
          {
            path: 'corporate-account',
            name: 'CorporateAccount',
            component: () => import('../views/kyc/CorporateAccount'),
          },
          {
            path: 'update-phone',
            name: 'UpdateNumber',
            component: () => import('../views/kyc/UpdateNumber'),
          },
          {
            path: 'corporate-account',
            name: 'CorporateAccount',
            component: () => import('../views/kyc/CorporateAccount'),
          },
          {
            path: 'update-phone',
            name: 'UpdateNumber',
            component: () => import('../views/kyc/UpdateNumber'),
          },
          {
            path: 'photo',
            name: 'Photo',
            component: () => import('../views/kyc/Photo'),
          },
          {
            path: 'add-document',
            name: 'AddDocument',
            component: () => import('../views/kyc/AddDocument'),
          },
          {
            path: 'verify-document/:type',
            name: 'VerifyDocument',
            component: () => import('../views/kyc/VerifyDocument'),
          },
          {
            path: 'verify-bvn',
            name: 'VerifyBvn',
            component: () => import('../views/kyc/VerifyBvn'),
          },
        ],
      },
      // ======================================================================   PIN Management

      {
        path: 'settlement-preference',
        component: () =>
          import('../views/kyc/pin-mangement/PinManagemntRouter'),
        beforeEnter: (to, from, next) => {
          const authRole = GET_USER_DATA().role
            ? GET_USER_DATA().role.toLowerCase()
            : '';

          if (authRole !== 'admin') {
            return false;
          } else {
            next();
          }
        },
        children: [
          {
            path: '',
            name: 'SettlementPreference',
            component: () =>
              import('../views/kyc/pin-mangement/SettlementPreference'),
          },
          {
            path: 'pin-management',
            name: 'PinManagment',
            component: () => import('../views/kyc/pin-mangement/PinManagement'),
          },
          {
            path: 'confirm-password',
            name: 'ConfirmPassword',
            component: () =>
              import('../views/kyc/pin-mangement/ConfirmPassword'),
          },
          {
            path: 'confirm-pin',
            name: 'ConfirmPinInput',
            component: () => import('../views/kyc/pin-mangement/Pin'),
          },
          {
            path: 'add-settlement-preference',
            name: 'AddSettlementPreference',
            component: () =>
              import('../views/kyc/pin-mangement/AddSettlementPreference'),
          },
          {
            path: 'business-settlement',
            name: 'BusinessSettlement',
            component: () =>
              import('../views/kyc/pin-mangement/BusinessSettlement'),
          },
          {
            path: 'store-settlement',
            name: 'StoreSettlement',
            component: () =>
              import('../views/kyc/pin-mangement/StoreSettlement'),
          },
        ],
      },
      // ======================================================================   Insights

      {
        path: 'insights',
        name: 'Insights',
        component: () => import('../views/dashboard/Insights'),
      },
      {
        path: 'view-insight',
        name: 'ViewInsights',
        component: () => import('../views/dashboard/InsightsTwo'),
      },
      {
        path: 'tickets',
        name: 'Tickets',
        component: () => import('../views/tickets/Tickets'),
      },
      {
        path: 'view-ticket',
        name: 'ViewTicket',
        component: () => import('../views/tickets/ViewTicket'),
      },
      // ======================================================================   Payments
      {
        path: 'payments',
        component: () => import('../views/Payments/Payment'),
        redirect: { name: 'Payments' },
        children: [
          {
            path: '',
            name: 'Payments',
            component: () => import('../views/Payments/MainPayments'),
          },
          {
            path: 'payouts',
            name: 'Payouts',
            component: () => import('../views/Payments/Payouts'),
          },
          {
            path: 'payout-details/:payoutId',
            name: 'PayoutDetails',
            component: () => import('../views/Payments/PayoutDetails'),
          },
        ],
      },
      // ======================================================================   Wallet
      {
        path: 'wallet',
        component: () => import('../views/wallet/Wallet'),
        beforeEnter: (to, from, next) => {
          const authRole = GET_USER_DATA().role
            ? GET_USER_DATA().role.toLowerCase()
            : '';

          if (authRole !== 'admin') {
            return false;
          } else {
            next();
          }
        },
        redirect: { name: 'MainWallet' },
        children: [
          {
            path: '',
            name: 'MainWallet',
            component: () => import('../views/wallet/MainWallet'),
          },
          {
            path: '',
            name: 'MainWalletLogin',
            component: () => import('../views/security/WalletAuthLogin'),
            path: '',
            name: 'MainWalletLogin',
            component: () => import('../views/security/WalletAuthLogin'),
          },
          {
            path: 'wallet-transaction-details',
            name: 'walletTransactionDetails',
            component: () => import('../views/wallet/WalletTransactionDetail'),
          },
          {
            path: 'wallet-transaction-details',
            name: 'walletTransactionDetails',
            component: () => import('../views/wallet/WalletTransactionDetail'),
          },
          {
            path: 'wallet-transaction-details-template',
            name: 'walletTransactionDetailsTemplate',
            component: () =>
              import('../views/wallet/WalletTransactionDetailTemplate'),
          },
          {
            path: 'beneficiaries',
            name: 'WalletBeneficiaries',
            component: () =>
              import('../views/wallet/beneficiaries/Beneficiaries'),
          },
          {
            path: 'wallet-transfer',
            name: 'WalletMainTransfer',
            component: () => import('../views/wallet/WalletTransfer'),
          },
          {
            path: 'open-account',
            name: 'OpenAccount',
            component: () => import('../views/wallet/OpenAccount'),
          },
          {
            path: 'create-pin',
            name: 'CreatePin',
            component: () => import('../views/wallet/CreatePin'),
          },

          {
            path: 'confirm-pin',
            name: 'ConfirmPin',
            component: () => import('../views/wallet/ConfirmPin'),
          },
          {
            path: 'account-details',
            name: 'AccountDetails',
            component: () => import('../views/wallet/AccountDetails'),
          },
          {
            path: 'fund-wallet/:type',
            name: 'FundWallet',
            component: () => import('../views/wallet/FundWallet'),
          },
          {
            path: 'create-wallet',
            name: 'CreateWallet',
            component: () => import('../views/wallet/CreateWallet'),
          },
          {
            path: 'pay-bills',
            name: 'PayBills',
            component: () => import('../views/wallet/PayBills'),
          },
          {
            path: 'bill-payment/:type',
            name: 'BillPayment',
            component: () => import('../views/wallet/BillPayment'),
          },
          {
            path: 'bill-payment-two/:type/:smartCardNumber/:package/:name/:code?',
            name: 'BillPaymentTwo',
            component: () => import('../views/wallet/BillPaymentTwo'),
          },
          {
            path: 'savings-waitlist',
            name: 'SavingsWaitlist',
            component: () => import('../views/wallet/SavingsWaitlist'),
          },
        ],
      },
      // ======================================================================   Customers
      {
        path: '/customers',
        name: 'Customers',
        component: () => import('../views/customers/Customers'),
      },
      {
        path: '/add-customer',
        name: 'AddCustomer',
        component: () => import('../views/customers/AddCustomer'),
      },
      {
        path: '/customer-details',
        name: 'CustomerDetails',
        component: () => import('../views/customers/CustomerDetails'),
      },
      {
        path: '/customer-loyalty',
        name: 'CustomerLoyalty',
        component: () => import('../views/customers/CustomerLoyalty'),
      },
      // ======================================================================   Products
      {
        path: '/products',
        name: 'Products',
        component: () => import('../views/products/Products'),
      },
      {
        path: '/products_v2',
        name: 'ProductsV2',
        component: () => import('../views/products/ProductsV2'),
      },
      {
        path: '/add-product',
        name: 'AddProduct',
        component: () => import('../views/products/AddProduct'),
      },
      {
        path: '/bulk-products-upload',
        name: 'BulkProductsUpload',
        component: () => import('../views/products/BulkProductUpload'),
      },
      {
        path: '/uploaded-batches',
        name: 'UploadedBatches',
        component: () => import('../views/products/AllUploadBatches'),
      },
      {
        path: '/products-upload-failed/:batchNumber',
        name: 'ProductFailedToUpload',
        component: () => import('../views/products/ProductFailedToUpload'),
      },
      // ======================================================================   Categories
      {
        path: '/categories',
        name: 'Categories',
        component: () => import('../views/categories/Categories'),
      },
      {
        path: '/categories_v2',
        name: 'CategoriesV2',
        component: () => import('../views/categories/CategoriesV2'),
      },
      {
        path: '/add-categories',
        name: 'AddCategories',
        component: () => import('../views/categories/AddCategories'),
      },
      {
        path: '/categories-list',
        name: 'CategoriesList',
        component: () => import('../views/categories/CategoriesListV2'),
      },
      // ======================================================================   POS
      {
        path: '/pos',
        name: 'Pos',
        component: () => import('../views/posV2/SelectStore'),
      },
      {
        path: '/pos-dashboard',
        name: 'PosDashboard',
        component: () => import('../views/posV2/POSDashboard'),
      },
      {
        path: '/pos-payment',
        name: 'PosPayment',
        component: () => import('../views/posV2/POSPaymentV2'),
      },
      // {
      //   path: "/digital-goods",
      //   name: "DigitalGoods",
      //   component: () => import("../views/pos/DigitalGoods"),
      // },
      // ======================================================================   Registers
      {
        path: '/registers',
        name: 'Registers',
        component: () => import('../views/registers/Registers'),
      },
      {
        path: '/registers-details',
        name: 'RegistersDetails',
        component: () => import('../views/registers/RegistersDetails'),
      },
      // ======================================================================   Invoices
      {
        path: '/invoices',
        name: 'Invoices',
        component: () => import('../views/invoices/Invoices'),
      },
      {
        path: '/add-invoice',
        name: 'AddInvoice',
        component: () => import('../views/invoices/AddInvoice'),
      },
      {
        path: '/invoice-details',
        name: 'InvoiceDetails',
        component: () => import('../views/invoices/InvoiceDetails'),
      },
      // ======================================================================   Hardware
      {
        path: '/hardware',
        name: 'Hardware',
        component: () => import('../views/hardware/TerminalsDashboard'),
      },
      {
        path: '/terminals',
        name: 'Terminals',
        component: () => import('../views/hardware/Terminals'),
      },
      {
        path: '/terminal-details',
        name: 'TerminalDetails',
        component: () => import('../views/hardware/TerminalDetails'),
      },
      {
        path: '/add-terminal',
        name: 'AddTerminal',
        component: () => import('../views/hardware/AddTerminal'),
      },
      {
        path: '/terminal-request',
        name: 'TerminalRequest',
        component: () => import('../views/hardware/TerminalRequest'),
      },
      {
        path: '/terminal-request/android',
        name: 'TerminalRequestAndroid',
        component: () => import('../views/hardware/TerminalRequestAndroid'),
      },
      {
        path: '/terminal-request/mpos',
        name: 'TerminalRequestMpos',
        component: () => import('../views/hardware/TerminalRequestMpos'),
      },
      {
        path: '/terminal-request/linux',
        name: 'TerminalRequestLinux',
        component: () => import('../views/hardware/TerminalRequestLinux'),
      },
      // ======================================================================   Sales History
      // {
      //   path: "/sales-history",
      //   name: "SalesHistory",
      //   component: () => import("../views/sales/SalesHistoryV2"),
      // },
      {
        path: '/sales-history',
        name: 'SalesHistory',
        component: () => import('../views/sales/SalesHistoryV2'),
      },
      {
        path: '/sales-history/:id',
        name: 'SaleInfo',
        component: () => import('../views/sales/SaleInfo'),
      },
      {
        path: '/sales-details/:id',
        name: 'SalesDetails',
        component: () => import('../views/sales/SalesDetails'),
      },
      {
        path: '/sales-report',
        name: 'Reports',
        component: () => import('../views/sales/sales-report/Reports'),
      },
      // ======================================================================   Orders
      // {
      //   path: '/orders',
      //   name: 'Orders',
      //   component: () => import('../views/orders/Orders'),
      // },
      // {
      //   path: '/order-details/:id',
      //   name: 'OrderDetails',
      //   component: () => import('../views/orders/OrderDetails'),
      // },
      // {
      //   path: '/add-payment/:id',
      //   name: 'AddPayment',
      //   component: () => import('../views/orders/AddPayment'),
      // },
      // ======================================================================   Stores
      {
        path: '/all-stores',
        name: 'AllStores',
        component: () => import('../views/stores/Stores'),
      },
      {
        path: '/add-store',
        name: 'AddStore',
        component: () => import('../views/stores/AddStore'),
      },
      // ======================================================================   Inventory Control
      // {
      //   path: '/stock-count',
      //   name: 'StockCount',
      //   component: () =>
      //     import('../views/inventory-control/stock-count/StockCount'),
      // },
      // {
      //   path: '/new-count',
      //   name: 'NewCount',
      //   component: () =>
      //     import('../views/inventory-control/stock-count/NewCount'),
      // },
      // {
      //   path: '/category-count',
      //   name: 'CategoryCount',
      //   component: () =>
      //     import('../views/inventory-control/stock-count/CategoryCount'),
      // },
      // {
      //   path: '/submit-count',
      //   name: 'SubmitCount',
      //   component: () =>
      //     import('../views/inventory-control/stock-count/SubmitCount'),
      // },
      // {
      //   path: '/accept-count',
      //   name: 'AcceptCount',
      //   component: () =>
      //     import('../views/inventory-control/stock-count/AcceptCount'),
      // },
      // {
      //   path: '/count-completed',
      //   name: 'CountCompleted',
      //   component: () =>
      //     import('../views/inventory-control/stock-count/CountCompleted'),
      // },
      // {
      //   path: '/add-stock',
      //   name: 'AddStock',
      //   component: () =>
      //     import('../views/inventory-control/stock-adjustment/AddStock'),
      // },
      // {
      //   path: '/remove-stock',
      //   name: 'RemoveStock',
      //   component: () =>
      //     import('../views/inventory-control/stock-adjustment/RemoveStock'),
      // },
      // {
      //   path: '/stock-adjustment',
      //   name: 'StockAdjustment',
      //   component: () =>
      //     import('../views/inventory-control/stock-adjustment/StockAdjustment'),
      // },
      // {
      //   path: '/add-stock-next',
      //   name: 'AddStockNext',
      //   component: () =>
      //     import('../views/inventory-control/stock-adjustment/AddStockNext'),
      // },
      // {
      //   path: '/remove-stock-next',
      //   name: 'RemoveStockNext',
      //   component: () =>
      //     import('../views/inventory-control/stock-adjustment/RemoveStockNext'),
      // },
      // {
      //   path: '/inventory-transfer',
      //   name: 'InventoryTransfer',
      //   component: () =>
      //     import(
      //       '../views/inventory-control/inventory-transfer/InventoryTransfer'
      //     ),
      // },
      // {
      //   path: '/create-transfer',
      //   name: 'CreateTransfer',
      //   component: () =>
      //     import(
      //       '../views/inventory-control/inventory-transfer/CreateTransfer'
      //     ),
      // },
      // {
      //   path: '/transfer-detail',
      //   name: 'TransferDetails',
      //   component: () =>
      //     import(
      //       '../views/inventory-control/inventory-transfer/TransferDetails'
      //     ),
      // },
      // {
      //   path: '/transfer-order',
      //   name: 'TransferOrder',
      //   component: () => import('../views/inventory-control/TransferOrder'),
      // },
      {
        path: '/print-label',
        name: 'PrintLabel',
        component: () => import('../views/inventory-control/PrintLabel'),
      },
      // ======================================================================   settings
      {
        path: '/company-info',
        name: 'CompanyInfo',
        component: () => import('../views/settings/CompanyInfo'),
        beforeEnter: (to, from, next) => {
          const authRole = GET_USER_DATA().role
            ? GET_USER_DATA().role.toLowerCase()
            : '';

          if (authRole !== 'admin') {
            return false;
          } else {
            next();
          }
        },
      },
      // {
      //   path: "/bank-accounts",
      //   name: "BankAccounts",
      //   component: () => import("../views/settings/BankAccounts"),
      // },
      {
        path: '/add-account',
        name: 'AddAccount',
        component: () => import('../views/settings/AddAccount'),
        beforeEnter: (to, from, next) => {
          const authRole = GET_USER_DATA().role
            ? GET_USER_DATA().role.toLowerCase()
            : '';

          if (authRole !== 'admin') {
            return false;
          } else {
            next();
          }
        },
      },
      {
        path: '/payment-methods',
        name: 'PaymentMethod',
        component: () => import('../views/settings/PaymentMethod'),
        beforeEnter: (to, from, next) => {
          const authRole = GET_USER_DATA().role
            ? GET_USER_DATA().role.toLowerCase()
            : '';

          if (authRole !== 'admin') {
            return false;
          } else {
            next();
          }
        },
      },
      {
        path: '/user-roles',
        name: 'UserRoles',
        component: () => import('../views/settings/UserRoles'),
        beforeEnter: (to, from, next) => {
          const authRole = GET_USER_DATA().role
            ? GET_USER_DATA().role.toLowerCase()
            : '';

          if (authRole !== 'admin') {
            return false;
          } else {
            next();
          }
        },
      },
      {
        path: '/existing-roles',
        name: 'ExistingRoles',
        component: () => import('../views/settings/ExistingRoles'),
        beforeEnter: (to, from, next) => {
          const authRole = GET_USER_DATA().role
            ? GET_USER_DATA().role.toLowerCase()
            : '';

          if (authRole !== 'admin') {
            return false;
          } else {
            next();
          }
        },
      },

      {
        path: '/manage-roles-permissions',
        name: 'ManageRolesPermissions',
        component: () => import('../views/settings/ManageRolesPermissions'),
        beforeEnter: (to, from, next) => {
          const authRole = GET_USER_DATA().role
            ? GET_USER_DATA().role.toLowerCase()
            : '';

          if (authRole !== 'admin') {
            return false;
          } else {
            next();
          }
        },
        // component: () =>
        //     import ("../views/settings/ManagePermissions"),
      },

      {
        path: '/user-settings',
        name: 'UserSettings',
        component: () => import('../views/settings/Users'),
        beforeEnter: (to, from, next) => {
          const authRole = GET_USER_DATA().role
            ? GET_USER_DATA().role.toLowerCase()
            : '';

          if (authRole !== 'admin') {
            return false;
          } else {
            next();
          }
        },
      },
      // {
      //   path: 'employee-menu',
      //   name: 'EmployeeMenu',
      //   component: () => import('../views/settings/EmployeeMenu'),
      //   beforeEnter: (to, from, next) => {
      //     const authRole = GET_USER_DATA().role
      //       ? GET_USER_DATA().role.toLowerCase()
      //       : '';

      //     if (authRole !== 'admin') {
      //       return false;
      //     } else {
      //       next();
      //     }
      //   },
      // },
      {
        path: '/employee-details-user',
        name: 'EmployeeDetailsUser',
        component: () => import('../views/settings/EmployeeDetailsUser'),
        beforeEnter: (to, from, next) => {
          const authRole = GET_USER_DATA().role
            ? GET_USER_DATA().role.toLowerCase()
            : '';

          if (authRole !== 'admin') {
            return false;
          } else {
            next();
          }
        },
      },
      {
        path: '/edit-employee-details-user',
        name: 'EditEmployeeDetailsUser',
        components: {
          default: () => import('../views/settings/EditEmployeeDetailsUser'),
          tabbedView: () => import('../views/settings/EditEmployeeDetailsUser'),
        },
        beforeEnter: (to, from, next) => {
          const authRole = GET_USER_DATA().role
            ? GET_USER_DATA().role.toLowerCase()
            : '';

          if (authRole !== 'admin') {
            return false;
          } else {
            next();
          }
        },
      },
      {
        path: '/online-catalogue',
        name: 'OnlineCatalogue',
        component: () => import('../views/onlinecatalogue/OnlineCatalogue'),
      },
      {
        path: '/employee-details-role',
        name: 'EmployeeDetailsRole',
        component: () => import('../views/settings/EmployeeDetailsRole'),
        beforeEnter: (to, from, next) => {
          const authRole = GET_USER_DATA().role
            ? GET_USER_DATA().role.toLowerCase()
            : '';

          if (authRole !== 'admin') {
            return false;
          } else {
            next();
          }
        },
      },
      {
        path: '/fixed-account',
        name: 'FixedAccount',
        component: () => import('../views/fixedAccounts/FixedAccount'),
      },
      // {
      //   path: '/user-guides',
      //   name: 'UserGuides',
      //   component: () => import('../views/UserGuides/UserGuides'),
      // },
      // ======================================================================   Integrations
      // {
      //   path: '/integrations',
      //   name: 'Integrations',
      //   component: () => import('../views/settings/integrations/Integrations'),
      // },
      // {
      //   path: '/integration-connection',
      //   name: 'IntegrationsConnection',
      //   component: () =>
      //     import('../views/settings/integrations/ConnectIntegration'),
      // },
    ],
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  routes,
  // mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 }; // Scroll to top
    }
  },
});

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     if (
//       !GET_LOCAL_DB_DATA('traction-app-user-data') && !GET_LOCAL_DB_DATA('traction-app-current-user-business-data')
//     ) {
//       next({ name: 'Login' });
//     } else {
//       next();
//     }
//   } else{
//     next()
//   }

//   if (to.matched.some(record => record.meta.hideForAuth)) {
//     if (GET_LOCAL_DB_DATA('traction-app-user-data') && GET_LOCAL_DB_DATA('traction-app-current-user-business-data')) {
//         next({ path: '/dashboard' });
//     } else {
//         next();
//     }
// } else {
//     next();
// }
// });

router.beforeEach((to, from, next) => {
  if (
    to.name !== 'ViewInvoiceWithoutAuth' &&
    to.name !== 'ForgotPassword' &&
    to.name !== 'Login' &&
    to.name !== 'Signup' &&
    to.name !== 'FcmbSignUp' &&
    to.name !== 'FcmbLogin' &&
    !GET_LOCAL_DB_DATA('traction-app-user-data') &&
    !GET_LOCAL_DB_DATA('traction-app-current-user-business-data')
  ) {
    // Check if any logged in user exists
    if (!!Object.keys(GET_ALL_AUTH_USERS()).length) {
      const selectedUser = GET_CURRENT_AUTH_USER(
        ITERATEALL_ALL_AUTH_USERS()[0].user._id
      );

      SAVE_LOCAL_DB_DATA('traction-app-user-data', selectedUser.userData);
      SAVE_LOCAL_DB_DATA(
        'secondary-auth-token',
        selectedUser.secondaryTokenData
      );
      SAVE_LOCAL_DB_DATA('auth-token-date', selectedUser.tokenTimeStamp);
      SAVE_LOCAL_DB_DATA(
        'traction-app-current-user-business-data',
        selectedUser.businessData
      );

      next();
    } else {
      next({ name: 'Login' });
    }
  } else {
    next();
  }
});

export default router;
