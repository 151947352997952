import { render, staticRenderFns } from "./AddModifierToProduct.vue?vue&type=template&id=ace0152e&scoped=true"
import script from "./AddModifierToProduct.vue?vue&type=script&lang=js"
export * from "./AddModifierToProduct.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ace0152e",
  null
  
)

export default component.exports