<template>
  <div class="flex justify-between">
    <h2 class="text-xl font-bold text-gray-800" v-bind="$attrs">
      {{ $attrs.title }}
    </h2>
    <slot />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<style scoped></style>
