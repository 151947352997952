<template>
    <div>
        <div class="tag">
            <h1 class="mb-2 font-semibold">{{ suppliedTag ? 'Edit' : 'Create' }} Tag</h1>
            <!-- <input v-model="tagText" type="text" placeholder="Add a tag" /> -->
            <trac-input class="my-6" placeholder="Enter tag name" v-model="tagText" data-test_id="input_test"></trac-input>
            <div class="mx-auto mt-6 w-1/2">
                <trac-button class="w-full" id="clickToAdd" :disabled="loading" @button-clicked="emitText()">
                    {{ suppliedTag ? 'Edit' : 'Create' }}
                </trac-button>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                tagText: this.suppliedTag || '',
                suppliedId: this.tagId || ''
            }
        },
        methods: {
            emitText() {
                if (this.suppliedTag) {
                    this.$emit('edited-tag', {
                        label: this.tagText,
                        id: this.suppliedId
                    });
                } else {
                    this.$emit('added-tag', this.tagText);
                }
            }
        },
        props: {
            suppliedTag: {
                type: String,
                default: '',
                required: false
            },
            tagId: {
                type: String,
                default: '',
                required: false
            },
            loading: {
                type: Boolean,
                default: false,
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>