import { eventBus } from "../../eventBus";
import ClickOutside from 'vue-click-outside'
export default {
  data() {
    return {
      showModal: false,
      search: '',
      tagTextHolder: '',
      tagIdHolder: '',
      currentTagIndex: -1,
      tags: [],
      loading: false,
      addTagSuccessModal: false,
      tagSuccessText: ''
    };
  },
  computed: {
    filteredList() {
      return this.search === ''
        ? this.tags
        : this.tags.filter((tag) =>
            tag.tagName.toLowerCase().includes(this.search.toLowerCase())
          );
    }
  },
  created () {
    this.loadTags()
  },
  directives: {
    ClickOutside
  },
  methods: {
    loadTags() {
      this.loading = true
      this.$store.dispatch('FETCH_TAGS').then((res) => {
        this.loading = false
        // console.log('FETCHED_TAGS: ',res)
        const list = (res.data || [])
        this.tags = list.map(tag => {
          return {
            _id: tag._id,
            tagName: tag.label
          }
        })
      })
    },
    toggleAddTagSuccessModal() {
      this.addTagSuccessModal = !this.addTagSuccessModal
    },
    addTag(data) {
      this.loading = true
      this.$store.dispatch('CREATE_TAGS', { label: data }).then((res) => {
        if(res.status){
          this.closeModal()
          this.tagSuccessText = 'Tag has been created successfully.'
          this.toggleAddTagSuccessModal()
          this.loadTags()
        } else {
          eventBus.$emit('trac-alert', { message: res.message })
        }
        this.loading = false
      })
    },
    reset() {
      console.warn('Resetting currentTagIndex');
      this.currentTagIndex = -1
      this.tagIdHolder = ''
    },
    closeModal() {
      this.showModal = false
      this.tagTextHolder = ''
    },
    editTag(tagName, tagId){
      this.tagTextHolder = tagName;
      this.tagIdHolder = tagId;
      this.showModal = true
    },
    updateTag(data) {
      this.loading = true
      console.log('data for update: ', data);
      this.$store.dispatch('UPDATE_TAG', { label: data.label, tagId: data.id }).then((res) => {
        if(res.status){
          this.reset()
          this.closeModal()
          this.tagSuccessText = 'Tag has been edited successfully.'
          this.toggleAddTagSuccessModal()
          this.loadTags()
          this.loading = false
        } else {
          eventBus.$emit('trac-alert', { message: res.response.data.message })
          this.loading = false
        }
      })
    },
    deleteTag(tagId) {
      this.closeModal()
      this.loading = true
      // create a dispatch for 'DELETE_TAG'. in the res object, if res.status is true, call loadTags(). else, call eventBus.$emit('trac-alert', { message: res.message })
      this.$store.dispatch('DELETE_TAG', tagId).then((res) => {
        if(res.status){
          this.loading = false
          this.tagSuccessText = 'Tag has been deleted successfully.'
          this.toggleAddTagSuccessModal()
          this.reset()
          this.loadTags()
        } else {
          eventBus.$emit('trac-alert', { message: res.message })
          this.loading = false
        }
      })
    }
  },
};