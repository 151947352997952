import Vue from "vue";
import Button from "@/components/components/Button";
import Tag from "@/components/components/Tag";
import CustomHeader from "@/components/components/CustomHeader";
import ImagePlaceholder from "@/components/components/ImagePlaceholder";
import BackButton from "@/components/components/BackButton";
import DropDownExtended from "@/components/components/DropDownExtended";
import CenterData from "@/components/components/CenterData";
import Toggle from "@/components/components/Toggle";
import Checkbox from "@/components/components/Checkbox";
import Textarea from "@/components/components/TextArea";
import Radio from "@/components/components/Radio";
import InputError from "@/components/components/InputError";
import Input from "@/components/components/Input";
import DropDownMultiSelect from "@/components/components/DropDownMultiSelect";
import Modal from "@/components/components/Modal";
import TopNav from "@/components/components/TopNav";
import Stepper from "@/components/components/Stepper";
import Dropdown from "@/components/components/Dropdown";
import Table from "@/components/components/Table";
import Loading from "@/components/components/Loading";
import PaymentDropdown from "../components/components/PaymentDropdown";
import Confirmation from "../components/components/confirmation";
import TabbedWindow from "../components/TabbedWindowComponent.vue";
import OfflineMessage from "../offline-module/components/OfflineMessageComponent.vue";
import FileUpload from "../../src/components/components/FileUpload.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import DatePicker from "vue2-datepicker";
import FilterControl from "../../src/components/components/FilterControl.vue";
import CustomDropdown from "@/components/components/CustomDropDown.vue";
import SuccessModal from "@/components/components/SuccessModal.vue";
import ProductTagList from "@/components/components/ProductTagList.vue";
import ProductModifiersList from "@/components/components/ProductModifiersList.vue";
import AddModifierToProduct from "../../src/views/Modifiers/AddModifierToProduct";
import HeadingInfo from "@/components/components/HeadingInfo.vue";
import Capsule from "@/components/components/Capsule.vue";
import SearchInput from "@/components/components/SearchInput.vue";
import DashboardTable from "@/components/components/DashboardTable";
import DashboardTableData from "@/components/components/DashboardTable/TableData";
import ImageUpload from "@/components/components/ImageUpload";
import InCorrectPin from "@/components/components/IncorrectPin.vue";
import OTPInput from "@/components/OTPInputComponent.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";

Vue.component("trac-upload", FileUpload);
Vue.component("trac-filter", FilterControl);
Vue.component("trac-offline", OfflineMessage);
Vue.component("trac-center-data", CenterData);
Vue.component("trac-custom-header", CustomHeader);
Vue.component("trac-image-placeholder", ImagePlaceholder);
Vue.component("trac-button", Button);
Vue.component("trac-back-button", BackButton);
Vue.component("trac-toggle", Toggle);
Vue.component("trac-checkbox", Checkbox);
Vue.component("trac-textarea", Textarea);
Vue.component("trac-radio", Radio);
Vue.component("trac-input-error", InputError);
Vue.component("trac-input", Input);
Vue.component("trac-multi-select-dropdown", DropDownMultiSelect);
Vue.component("trac-modal", Modal);
Vue.component("trac-topnav", TopNav);
Vue.component("trac-stepper", Stepper);
Vue.component("trac-dropdown", Dropdown);
Vue.component("trac-dropdown-exteneded", DropDownExtended);
Vue.component("trac-table", Table);
Vue.component("trac-loading", Loading);
Vue.component("trac-confirmation", Confirmation);
Vue.component("trac-payment-dropdown", PaymentDropdown);
Vue.component("trac-date-picker", DatePicker);
Vue.component("trac-validation-provider", ValidationProvider);
Vue.component("trac-validation-observer", ValidationObserver);
Vue.component("trac-tab-window", TabbedWindow);
Vue.component("trac-custom-dropdown", CustomDropdown);
Vue.component("trac-success-modal", SuccessModal);
Vue.component("trac-tag", Tag);
Vue.component("trac-tag-list", ProductTagList);
Vue.component("trac-modifiers-list", ProductModifiersList);
Vue.component("trac-add-modifier", AddModifierToProduct);
Vue.component("trac-heading-info", HeadingInfo);
Vue.component("trac-capsule", Capsule);
Vue.component("trac-search-input", SearchInput);
Vue.component("trac-dashboard-table", DashboardTable);
Vue.component("trac-dashboard-table-data", DashboardTableData);
Vue.component("trac-image-upload", ImageUpload);
Vue.component("trac-incorrect-pin", InCorrectPin);
Vue.component("trac-otp-input", OTPInput);
Vue.component("vue-google-autocomplete", VueGoogleAutocomplete);
